export const PAGE_DATA = {
    navigator: {
        contentModular: [
            {
                title: '内容01',
                pageId: 'navigator01',
                path: '/default',
            },
            {
                title: '内容02',
                pageId: 'navigator02',
                path: '/navigator02',
            },
            {
                title: '聊天',
                pageId: 'chat',
                path: '/chat',
            },
            {
                title: '内容04',
                pageId: 'navigator04',
                path: '/navigator04',
            },
        ],
        secondaryModular: [
            {
                title: '新建群聊',
                pageId: 'addUsersChat',
                path: '/',
            },
            {
                title: '好友管理',
                pageId: 'myUser',
                path: '/',
            },
            {
                title: '系统消息',
                pageId: 'systemMessage',
                path: '/',
            },
        ],
    },
    chat: {
        p3: {
            title: '次要内容03',
            pageId: 'navigator07',
            path: '/', 
        }
    }
} 