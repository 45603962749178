import { loginTypes } from "../../common/dto/login/login.dto"
import { LOGIN_SP } from "../../common/springBoot/springBootUrl"
import { getPostData } from "../../common/utils/utils"
import Cookies from 'js-cookie'

export class LoginAction {
    public static actions = {
        login: (userId: any, password: any, callBack?: any) => {
            return async (dispatcher: any) => {
                let params = {
                    "userId": userId,
                    "password": password
                }
                const successCallBack =async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        let userData = {
                            userId: userId,
                            userName: apiData.userData.userName,
                            toKenId: apiData.toKenId
                        }
                        Cookies.set('userToken', apiData.toKenId, { expires: 1 })
                        Cookies.set('loginUserId', userId, { expires: 1 })
                        dispatcher(
                            {type:loginTypes.SET_ISLOGIN, isLogin: true}
                        )
                        dispatcher(
                            {type:loginTypes.SET_USER_DATA, userData: userData}
                        )
                        callBack()
                        // window.open('/')
                        // routerActions.push('/')
                        // navigate('/register')
                    } else if (successMessage.code === '202') {
                        alert('密码或者账户错误！！')
                    } else {
                        alert('登陆失败')
                    }
                }
                getPostData(LOGIN_SP.doLogin, params, successCallBack)
            }
        },
        love: (loveFlg: any, loveText: any, callBack?: any) => {
            return async () => {
                let params = {
                    "loveFlg": loveFlg,
                    "loveText": loveText,
                }
                const successCallBack = (successMessage: any) => {
                    if (successMessage.code === '200') {
                        callBack()
                    } else if (successMessage.code === '202') {
                        alert('心意上传失败！！')
                    } else {
                        alert('心意上传失败！！')
                    }
                }
                getPostData(LOGIN_SP.loveDev, params, successCallBack)
            }
        },
        lovePro: (loveFlg: any, loveText: any, callBack?: any) => {
            return async () => {
                let params = {
                    "loveFlg": loveFlg,
                    "loveText": loveText,
                }
                const successCallBack = (successMessage: any) => {
                    if (successMessage.code === '200') {
                        callBack()
                    } else if (successMessage.code === '202') {
                        alert('心意上传失败！！')
                    } else {
                        alert('心意上传失败！！')
                    }
                }
                getPostData(LOGIN_SP.lovePro, params, successCallBack)
            }
        }
    }
}

function setToken() {
    throw new Error("Function not implemented.")
}
