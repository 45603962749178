import { CircularProgress } from '@mui/material';
import * as React from 'react';
import './loadingPage.css'

type Props = {
    title?: string
  }
  
export default function LoadingPage(props: Props) {
    return (
        <div className='loadingPage'>
            <CircularProgress />
        </div>
    )
}