import { LoginDto } from "../../common/dto/login/login.dto";
import { Button, Container, Grid, Paper, StyledEngineProvider, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { StrictMode, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Navigate, redirect, useActionData, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { LoginAction } from "./loginAction";
import Cookies from "js-cookie";
import { store } from "../../app/store";
import { getPageId, getPath, isLogin } from '../../common';

interface Props {
    // your props validation
    actions: typeof LoginAction.actions
    loginDto: LoginDto
}

export class LoginPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            userId: '',
            userPassword: '',
            isLoginFlg: false
        }
    }

    componentDidMount() {

    }

    onChangeLoginId(userId: String) {
        this.setState({ userId: userId })
    }

    onChangePassword(password: String) {
        this.setState({ password: password })
    }

    onClickLogin() {
        const { userId, password } = this.state
        const successCallBack = () => {
            // alert('登陆成功')
            // alert('test:' + Cookies.get('userToken'))
            // alert('test:' + JSON.stringify(this.props.loginDto.userData))
            // alert('test111:' + this.props.loginDto.isLogin)
            // alert('testset:' + store.getState().loginDto.isLogin)
            // toPage('', '/')

            // alert('ttttttttt:'+isLogin())
            // alert('ddd:'+JSON.stringify(window.history))
            this.setState({ isLoginFlg: true })

        }
        if (userId && password) {
            this.props.actions?.login(userId, password, successCallBack)
        }
    }

    render() {
        const { userId, password, isLoginFlg } = this.state
        return (
            // <Box sx={{width: '100%',height: '100%',}}>
            //     {/* <Container sx={{width: '100%',height: '100%',}} > */}
            //     <Container maxWidth="sm" fixed sx={{width: '300px'}}>
            //         <TextField sx={{width: '100%', margin: '10px'}} id="textField-loginId" label="用户ID" variant="outlined" onChange={e=>this.onChangeLoginId(e?.currentTarget?.value)} value={this.state.userId} />
            //         <TextField sx={{width: '100%', margin: '10px'}} id="textField-password" label="用户密码" variant="outlined" onChange={e=>this.onChangePassword(e?.currentTarget?.value)} value={this.state.password} />
            //         <Button sx={{width: '100%', margin: '10px'}} variant={userId && password ? "contained" : 'outlined'}>登  录</Button>
            //     </Container>
            // </Box>
            <StrictMode>
                <StyledEngineProvider injectFirst>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#e2f5ff',
                        top: 0,
                        bottom: 0,
                        position: 'absolute',
                    }}>
                        <Container maxWidth="sm" fixed sx={{}}>
                            <Paper
                                sx={{
                                    p: 2,
                                    margin: 'auto',
                                    maxWidth: 500,
                                    padding: 1,
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    marginTop: 20,
                                    marginRight: 1,
                                    marginLeft: 1,
                                    // opacity: 0.3,
                                }}
                            >
                                <h2 style={{ textAlign: 'center' }}>登录账号</h2>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm container>
                                        <TextField sx={{ width: '100%', margin: 1 }} id="textField-loginId" label="用户ID" variant="outlined" onChange={e => this.onChangeLoginId(e?.currentTarget?.value)} value={this.state.userId} />
                                        <TextField
                                            sx={{ width: '100%', margin: 1 }}
                                            id="textField-password" label="用户密码"
                                            type="password"
                                            autoComplete="current-password"
                                            onChange={e => this.onChangePassword(e?.currentTarget?.value)} value={this.state.password} />
                                        <Button
                                            sx={{ width: '100%', margin: 1 }} variant={userId && password ? "contained" : 'outlined'}
                                            onClick={() => this.onClickLogin()}
                                        >登  录</Button>
                                    </Grid>
                                </Grid>
                                <Link to={'/register'}>注册新用户</Link>
                                {
                                    isLoginFlg && (<Navigate to="/" replace={true} />)
                                }
                            </Paper>
                        </Container>
                    </Box>
                </StyledEngineProvider>
            </StrictMode>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        loginDto: state.loginDto,
        commonDto: state.commonDto
    }
}

export const Login = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(LoginAction.actions, dispatch) })
)(LoginPage)