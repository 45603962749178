import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from './app/store'
import { Provider, } from 'react-redux'
import createBrowserHistory from 'history/createBrowserHistory';
import { ConnectedRouter } from 'react-router-redux';
import { HistoryRouterProps, BrowserRouter, HashRouter, Router } from 'react-router-dom';
// import { HashRouter  as Router  } from "react-router-dom";

import { WebSocketProvider } from "react-use-websockets";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <WebSocketProvider
    url="wss://api.nnnfhweubwys.bond:8081/myto/dev/ws-connect?satoken=957714de-5dbb-4f9f-9130-36c47daed00b"
    delimiter="\t" id={''} domain={''}>
    <Provider store={store}>
      <App />
    </Provider>
  </WebSocketProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
