import * as React from 'react';
import { Box, Button, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tab, Tabs, TextField } from '@mui/material';
import { SystemMessageAction } from './systemMessageAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import './systemMessagePage.css'
import { UserInfo, SelectAlert } from '../../components';

interface Props {
    actions: typeof SystemMessageAction.actions
    loginDto: LoginDto
    commonDto: CommonDto
}

export class SystemMessagePage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            messagesList: [],
            addAlert: false,
            loadingFlg: false,
            addUserId: '',
            addUserName: '',
        }
    }

    async componentDidMount() {
        this.getSystemMessageList();
        // TODO
        this.getChatList();
    }

    async getSystemMessageList() {
        try {
            this.setState({loadingFlg: true });
            const callback = (syatemMessageList: any) => {
                this.setState({messagesList: syatemMessageList, loadingFlg: false });
            }
            this.props.actions.getSystemMessageList(callback)
        } catch (e: any) {
            console.log(e);
        }
    }

    userNameShow(name: String) {
        return name.length > 10 ? name.substring(0, 10) + '...' : name;
    }



    listItem(messageData: any) {
        return (
            <ListItem className='messageItem'>
                <ListItemButton sx={{padding: 0, height: '50px'}}>
                    <ListItemIcon>
                        <UserInfo style={{maxHeight: '30px'}} nameLeftFlg={false} userIconUrl={require('../../assets/img/0221019102904.png')} />
                    </ListItemIcon>
                    <ListItemText primary={this.userNameShow(messageData?.userName)} />
                </ListItemButton>
                <Button
                    sx={{ width: 60, margin: 1, padding: 1 }} variant={messageData?.isMyContactFlg ? "outlined" : 'contained'}
                    onClick={()=>this.onClickAdd(messageData)}
                >
                    {'同 意'}
                </Button>
            </ListItem>
        )
    }

    onClickAdd(user: any) {
        this.setState({
            addAlert: true,
            addUserId: user?.userId,
            addUserName: user?.userName,
        })
    }

    onClickCleanAdd() {
        this.setState({addAlert: false, addUserId: '', addUserName: ''})
    }

    async getChatList() {
        this.props.actions?.getChatList(()=>{})
    }

    onClickAddOk() {
        const {addUserId, addUserName} = this.state;
        const callBack = () => {
            this.setState({addAlert: false, addUserId: '', addUserName: ''})
            this.getSystemMessageList();
            this.getChatList();
        }
        this.props.actions.addUserOk(addUserId, addUserName, callBack)
    }

    render() {
        const { messagesList, addAlert, loadingFlg } = this.state
        const { commonDto } = this.props
        return (
            <Box className='systemMessagePage' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: 0, margin: 0, width: '-webkit-fill-available', position: 'fixed', top: 64, bottom: 0 }}>
                <h3 className='headText'>系统消息</h3>
                <div className='contacts_area'>
                <SelectAlert open={addAlert} text={'请确认是否添加该好友！！！'}
                    onClicNo={()=>this.onClickCleanAdd()} onClickOk={()=>this.onClickAddOk()} />
                    <Paper sx={{flex: 1, margin: 1, padding: 1, display: 'flex'}}>
                        {loadingFlg && <div className='loading_area'>
                            <CircularProgress />
                        </div>}
                        <List className='messageList' disablePadding>
                        {
                            commonDto?.systemMessageList.map((message:any)=>{
                                return this.listItem(message)
                            })
                        }
                        </List>
                    </Paper>
                </div>
            </Box>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const SystemMessagePageContainer = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(SystemMessageAction.actions, dispatch) })
)(SystemMessagePage)