import { CHATLIST_SP, CHAT_SP, LOGIN_SP, SET_SP } from "../../common/springBoot/springBootUrl"
import { chatListFlertNew, getPostData } from "../../common/utils/utils"
import Cookies from 'js-cookie'
import { push } from "react-router-redux"
import { useNavigate } from "react-router-dom"
import { routerActions } from 'react-router-redux'
import { store } from "../../app/store"
import { chatTypes } from "../../common/dto/chat/chat.dto"
import { commonTypes } from "../../common/dto/common/common.dto"
var pushJs = require('push.js');

export class ChatAction {
    public static actions = {
        getChatList: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "userId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                        callBack(apiData.chatList)
                    } else {
                        alert('获取失败')
                    }
                }
                getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
            } else {
                setTimeout(()=>{
                    const userDataNew = store.getState().loginDto.userData
                    if (!userDataNew?.userId) {
                        return;
                    }
                    let paramsNew = {
                        "userId": userDataNew?.userId,
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let apiData = successMessage.dataList
                            store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                            callBack(apiData.chatList)
                        } else {
                            alert('获取失败')
                        }
                    }
                    getPostData(CHATLIST_SP.getChatLists, paramsNew, successCallBack)
                }, 2000)
            }

        },
        setChatList: (chatList: any, callBack?: any) => {
            const userData = store.getState().loginDto.userData
            store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(chatList, userData?.userId)})
            callBack();
        },
        selectChat: (chatId: any, callBack?: any) => {
            const chatList = store.getState().chatDto.chatList
            chatList.map(async (chat:any)=>{
                if (chatId == chat?.chatId) {
                    store.dispatch({type:chatTypes.SET_SELECT_CHAT_DATA, selectChatData: chat})
                    callBack();
                } 
            })
        },
        getUserList: (getFindUserId: any, callBack?: any) => {
            // return (dispatcher: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "userId": userData?.userId,
                "getFindUserId": getFindUserId
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    let userList = successMessage.dataList.userList
                    callBack(userList)
                } else {
                    alert('获取失败')
                }
            }
            getPostData(CHATLIST_SP.getFindContactsList, params, successCallBack)
        },
        addContacts: (addUserId: any, callBack?: any) => {
            // return (dispatcher: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "userId": userData?.userId,
                "contactUserId": addUserId
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    alert('获取失败')
                }
            }
            getPostData(CHATLIST_SP.addContacts, params, successCallBack)
        },
        getContactsList: (callBack?: any) => {
            // return (dispatcher: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "userId": userData?.userId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    let contactsList = successMessage.dataList?.contactsList
                    callBack(contactsList)
                } else {
                    callBack([])
                    // alert('获取失败')
                }
            }
            getPostData(CHATLIST_SP.getContactsList, params, successCallBack)
        },
        createRoom: (chatName: any, chatUser: any, callBack?: any) => {
            const userId = store.getState().loginDto.userData?.userId
            const userList = [...chatUser, userId]
            let params = {
                "getChatName": chatName,
                "userId": userId,
                "chatUser": userList
            }
            const successCallBack = (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else if (successMessage.code === '202') {
                    alert('聊天室名和用户不可用！！')
                } else {
                    alert('创建失败！！')
                }
            }
            getPostData(CHATLIST_SP.createChat, params, successCallBack)
        },
        getChatMessageList: (chatId: String, callBack?: any) => {
            // return (dispatcher: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "chatId": chatId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    let messages = successMessage.dataList?.messageList
                    messages.sort((a: any, b: any) =>{
                        const dateA: any = new Date(a.createDate);
                        const dateB: any = new Date(b.createDate);
                        return dateA - dateB;
                    });
                    callBack(messages)
                } else {
                    alert('获取失败')
                }
            }
            getPostData(CHAT_SP.getChatMessageList, params, successCallBack)
        },
        createMessage: (chatId: String, text: String, filePath: String, messageType: String, callBack?: any) => {
            // return (dispatcher: any) => {
            const userId = store.getState().loginDto.userData?.userId
            let params = {
                "sendUserId": userId,
                "chatId": chatId,
                "text": text,
                "filePath": filePath,
                "messageType": messageType,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack(true)
                } else {
                    callBack(false)
                }
            }
            getPostData(CHAT_SP.createMessage, params, successCallBack)
        },
        newMessage: (newMessageData: any) => {
            const userId = store.getState().loginDto.userData?.userId

            if (newMessageData?.messageId) {
                let params = {
                    "messageId": newMessageData?.messageId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage?.code === '200') {
                        const messageInfo = successMessage?.dataList?.messageDataList[0];
                        store.dispatch({type:chatTypes.SET_NEW_MESSAGE, newMessage: messageInfo});
                        const chatName = messageInfo?.chatName.length > 10 ? messageInfo?.chatName.substring(0, 10) + '...' : messageInfo?.chatName;
                        const text = messageInfo?.text.length > 10 ? messageInfo?.text.substring(0, 18) + '...' : messageInfo?.text;
                        if (userId == messageInfo?.sendUserId) {
                            return;
                        }
                        pushJs.create(chatName, {
                            body: text,
                            // icon: icon,
                            timeout: 3000,
                            onClick: function () {
                            window.focus();
                            this.close();
                            },
                        });
                    } else {
                        console.log('test')
                    }
                }
                getPostData(CHAT_SP.getNewMessage, params, successCallBack);
            }
        },
        deleteMyUser: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            const otherUserIdString = store.getState().chatDto.selectChatData?.chatNameY
            let otherUserIdListJson = JSON.parse(otherUserIdString)
            let otherUserIdList = Object.keys(otherUserIdListJson);
            let otherUserId = '';
            otherUserIdList.map((userId: string)=>{
                if (userData?.userId != userId) {
                    otherUserId = userId;
                }
            })

            const selectChatId = store.getState().chatDto.selectChatData?.chatId
            
            let params = {
                "myUserId": userData?.userId,
                "chatId": selectChatId,
                "otherUserId": otherUserId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    callBack()
                    alert('删除失败')
                }
            }
            getPostData(CHAT_SP.deleteMyUser, params, successCallBack)
        },
        exitChat: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            const selectChatId = store.getState().chatDto.selectChatData?.chatId
            let params = {
                "myUserId": userData?.userId,
                "chatId": selectChatId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    callBack()
                    alert('删除失败')
                }
            }
            getPostData(CHAT_SP.exitChat, params, successCallBack)
        },
        deleteChat: (callBack?: any) => {
            const selectChatId = store.getState().chatDto.selectChatData?.chatId
            let params = {
                "chatId": selectChatId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    callBack()
                    alert('删除失败')
                }
            }
            getPostData(CHAT_SP.deleteChat, params, successCallBack)
        },
        getChatUserList: (chatId: String, callBack?: any) => {
            let params = {
                "chatId": chatId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    let contactsList = successMessage.dataList?.contactsList
                    callBack(contactsList);
                } else {
                    callBack([]);
                }
            }
            getPostData(CHATLIST_SP.getChatContactsList, params, successCallBack);
        },
        changeChatName: (chatName: any, callBack?: any) => {
            const selectChatId = store.getState().chatDto.selectChatData?.chatId
            let params = {
                "chatName": chatName,
                "chatId": selectChatId,
            }
            const successCallBack = (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    alert('变更失败！！')
                }
            }
            getPostData(CHAT_SP.changeChatName, params, successCallBack)
        },
        addChatUser: (chatUser: any, callBack?: any) => {
            const selectChatId = store.getState().chatDto.selectChatData?.chatId
            const userList = [...chatUser]
            let params = {
                "chatId": selectChatId,
                "chatUser": userList
            }
            const successCallBack = (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    alert('添加失败！！')
                }
            }
            getPostData(CHAT_SP.addChatUser, params, successCallBack)
        },
        getSystemMessageList: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "myUserId": userData?.userId,
                }

                pushJs.create('好友添加通知', {
                    body: '有用户正在添加你，快去系统消息查看吧！',
                    // icon: icon,
                    timeout: 3000,
                    onClick: function () {
                        window.focus();
                        this.close();
                    },
                });
                
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        store.dispatch({type:commonTypes.SET_NEW_SYSTEMMESSAGELIST, systemMessageList: apiData.syatemMessageList})
                        callBack(apiData.syatemMessageList)
                    } else {
                        // alert('获取失败')
                    }
                }
                getPostData(SET_SP.getSystemMessagList, params, successCallBack)
            }
        },
    }
}

function setToken() {
    throw new Error("Function not implemented.")
}
