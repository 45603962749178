import React from "react";
import './HomePage.css';
// import logo from './logo.svg';
// import { Button } from '../../components/Buttom'
import Button from '@mui/material/Button';
import { pink } from '@mui/material/colors';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketDemo, WebSocket } from "../../components";

function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

export class HomePage extends React.Component {

    render() {
        return <>
            <div className="App">
                <header className="App-header">
                    {/* <img src={logo} className="App-logo" alt="logo" /> */}
                    <Link to={'/login'}>
                        <Button style={{fontSize: 40}}>登  录</Button>
                    </Link>
                    <Link to={'/test'}>Test</Link>
                    <Link to={'/chatlaoding'}>Test</Link>
                    <p>
                        Edit <code>src/App.tsx</code> and save to reload1.
                    </p>
                </header>
            </div>
        </>
    }
}
