import { LOGIN_SP } from "../../common/springBoot/springBootUrl"
import { getPostData } from "../../common/utils/utils"

export class RegisterAction {
    public static actions = {
        register: (userName: any, userId: any, password: any, checkPassword: any, userMail: any, callBack?: any) => {
            return async () => {
                let params = {
                    "userName": userName,
                    "userId": userId,
                    "password": password,
                    "userMail": userMail
                }
                if (password !== checkPassword) {
                    alert('两次输入的密码不一致！！')
                } else {
                    const successCallBack = (successMessage: any) => {
                        if (successMessage.code === '200') {
                            callBack()
                        } else if (successMessage.code === '202') {
                            alert('该账户不可注册！！')
                        } else {
                            alert('注册失败')
                        }
                    }
                    getPostData(LOGIN_SP.register, params, successCallBack)
                }
            }
        }
    }
}