// import { configureStore } from '@reduxjs/toolkit'
// // ...

// const store = configureStore({
//   reducer: {
//     posts: postsReducer,
//     comments: commentsReducer,
//     users: usersReducer,
//   },
// })

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch



import { createStore, applyMiddleware, combineReducers, compose, DeepPartial } from 'redux'
import thunk from 'redux-thunk'
import * as LoginDto from '../common/dto/login/login.dto'
import * as ChatDto from '../common/dto/chat/chat.dto'
import * as CommonDto from '../common/dto/common/common.dto'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
var createHistory = require("history").createBrowserHistory

// export const history = createHistory()
export const history = createBrowserHistory()
/* reducerとinitialStateに使用するDTOを定義する */
const enhancers: never[] = []
const middleware = [
    thunk,
    routerMiddleware(history)
]

const composedEnhancers: any = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const reducer = combineReducers({
    loginDto: LoginDto.reducer,
    chatDto: ChatDto.reducer,
    commonDto: CommonDto.reducer,
})

interface InitialStateType {
    loginDto: any
    chatDto: any
    commonDto: any
}
export const initialState: DeepPartial<InitialStateType> = {
    loginDto: LoginDto.initialState,
    chatDto: ChatDto.initialState,
    commonDto: CommonDto.initialState,
}
export const rootReducer = (state: any, action: any) => {
    return reducer(state, action)
}

export const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
)