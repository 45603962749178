import * as React from 'react';
import { Box, CircularProgress, ListItem, Tab, Tabs, TextField } from '@mui/material';
import { MyUserChatAction } from './myUserChatAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import './myUserChatPage.css'

interface Props {
    actions: typeof MyUserChatAction.actions
    loginDto: LoginDto
    commonDto: CommonDto
}

export class MyUserChatPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            userId: '',
            messagesList: [],
            contactList: [],
            loading: false,
            text: '',
            notChatFlg: false,
            isShowChatSettingFlg: false,
            newMessageInfo: {},
            value: 0,
        }
    }

    a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    render() {
        const { messagesList, text, notChatFlg, loading, isShowChatSettingFlg, value } = this.state
        return (
            <Box className='MyUserChatPage' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: 0, margin: 0, width: '-webkit-fill-available', position: 'fixed', top: 64, bottom: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={()=>{}} aria-label="basic tabs example">
                        <Tab label="添加联系人" {...this.a11yProps(0)} />
                        <Tab label="加入聊天室" {...this.a11yProps(1)} />
                        <Tab label="创建聊天室" {...this.a11yProps(2)} />
                        <Tab label="管理联系人" {...this.a11yProps(3)} />
                    </Tabs>
                </Box>
                <div>test</div>
            </Box>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const MyUserChatPageContainer = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(MyUserChatAction.actions, dispatch) })
)(MyUserChatPage)