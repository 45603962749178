export const PAGES = [
    {
        pageName: '',
        pageId: 'home',
        path: '/',
        title: 'home',
        describe: '',
    },{
        pageName: '',
        pageId: 'navigator01',
        path: '/default',
        title: '内容01',
        describe: '',
    }, {
        pageName: '',
        pageId: 'navigator02',
        path: '/navigator02',
        title: '聊天',
        describe: '',
    }, {
        pageName: '',
        pageId: 'chat',
        path: '/chat',
        title: '内容03',
        describe: '',
    }, {
        pageName: '',
        pageId: 'navigator04',
        path: '/navigator04',
        title: '内容04',
        describe: '',
    }
]

export const PAGE_DATA = {
    navigator: {
        contentModular: [
            {
                title: '内容01',
                pageId: 'navigator01',
                path: '/default',
            },
            {
                title: '聊天',
                pageId: 'navigator02',
                path: '/navigator02',
            },
            {
                title: '内容03',
                pageId: 'chat',
                path: '/chat',
            },
            {
                title: '内容04',
                pageId: 'navigator04',
                path: '/navigator04',
            },
        ],
        secondaryModular: [
            {
                title: '好友管理',
                pageId: 'myUserChat',
                path: '/',
            },
            {
                title: '新建聊天室',
                pageId: 'navigator06',
                path: '/',
            },
            {
                title: '次要内容03',
                pageId: 'navigator07',
                path: '/',
            },
        ],
    }
} 