import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, fabClasses } from '@mui/material'
import * as React from 'react'
import { AnyIfEmpty } from 'react-redux'
import './SelectAlert.css'

type Props = {
    open: boolean
    style?: any
    dialogContentStyle?: any
    headText?: string
    text: string
    notButtonOkFlg?: boolean
    notButtonNoFlg?: boolean
    onClickOk?: any
    onClicNo?: any
    okText?: string
    noText?: string
    view?: any
    fullScreen?: boolean
    fullWidth?: boolean
    maxWidth?: any
}
export const SelectAlert = (props: Props) => {
    const { open, headText, text, onClickOk, onClicNo, okText, noText, view, style, fullScreen, maxWidth, fullWidth, dialogContentStyle, notButtonOkFlg, notButtonNoFlg } = props;
    const [showFlg, setShowFlg] = React.useState(open?open:false);
    const handleClose = () => {
        setShowFlg(false);
    };
    return (
        <Dialog classes={style} open={open?open:showFlg} onClose={onClicNo?onClicNo:handleClose} maxWidth={maxWidth} fullScreen={fullScreen} fullWidth={fullWidth}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {headText?headText:''}
            </DialogTitle>
            <DialogContent id='test1' sx={dialogContentStyle}>
                <DialogContentText>
                    {text}
                </DialogContentText>
                {view}
            </DialogContent>
            <DialogActions id='test2'>
                {
                    !notButtonNoFlg && <Button autoFocus onClick={onClicNo?onClicNo:handleClose}>
                        {noText?noText:'取消'}
                    </Button>
                }
                {
                    !notButtonOkFlg && <Button onClick={onClickOk}>{okText?okText:'确认'}</Button>
                }
            </DialogActions>
      </Dialog>
    );
}
