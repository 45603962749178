// Login 登录用
export const LOGIN_SP = {
    doLogin: '/user/doLogin',
    register: '/user/register',
    isLoginTest: '/user/isLoginTest',
    checkLogin: '/user/checkLogin',
    logout: '/user/logout',
    loveDev: '/user/loveDev',
    lovePro: '/user/lovePro',
}

// 通用
export const COMMON_SP = {
    getUserName: '/chat/getUserName'
}

// chatList 聊天室一览用
export const CHATLIST_SP = {
    getChatLists: '/chat/getChatList',
    getMyUserList: '/chat/getMyUserList',
    createChat: '/chat/createChat',
    upChat: '/chat/upChatData',
    getChat: '/chat/getChatData',
    getFindContactsList: '/chat/getFindContactsList',
    addContacts: '/chat/addContacts',
    getContactsList: '/chat/getContactsList',
    getChatContactsList: '/chat/getChatContactsList',
}

// chat 聊天室用
export const CHAT_SP = {
    createMessage: '/chat/createMessage',
    getChatMessageList: '/chat/getChatMessageList',
    getChatNewMessages: '/chat/getChatNewMessages',
    getNewMessage: '/chat/getNewMessage',
    exitChat: '/chat/exitChat',
    deleteChat: '/chat/deleteChat',
    deleteMyUser: '/chat/deleteMyUser',
    changeChatName: '/chat/changeChatName',
    addChatUser: '/chat/addChatUser',
}

// set 好友聊天室设置用
export const SET_SP = {
    addUserPush: '/set/addUserPush',
    agreeAddUser: '/set/agreeAddUser',
    getFindUserList: '/set/getFindUserList',
    deleteMyUser: '/set/deleteMyUser',
    getSystemMessagList: '/set/getSystemMessagList',
}