import React from "react";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

interface Props {
    // your props validation
}

// export class ContentPage01 extends React.Component<Props, any> {
export class ContentPage01 extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {
            date: new Date(),
            selectContent: '',
        };
    }
    componentDidMount() {
        this.setState({})
    }
    render() {
        return <>
            <Stack component="form" noValidate spacing={3}>
                <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    defaultValue="2017-05-24"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="time"
                    label="Alarm clock"
                    type="time"
                    defaultValue="07:30"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    sx={{ width: 150 }}
                />
                <TextField
                    id="datetime-local"
                    label="Next appointment"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    sx={{ width: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
        </>
    }
}
