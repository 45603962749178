import { CHATLIST_SP, CHAT_SP, LOGIN_SP, SET_SP } from "../../common/springBoot/springBootUrl"
import { chatListFlertNew, getPostData } from "../../common/utils/utils"
import Cookies from 'js-cookie'
import { push } from "react-router-redux"
import { useNavigate } from "react-router-dom"
import { routerActions } from 'react-router-redux'
import { store } from "../../app/store"
import { chatTypes } from "../../common/dto/chat/chat.dto"
var pushJs = require('push.js');

export class MyUserAction {
    public static actions = {
        getUserList: (callBack?: any) => {
            let userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "userId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let contactsList = successMessage.dataList?.contactsList
                        callBack(contactsList);
                    } else {
                        callBack([]);
                        // alert('获取失败')
                    }
                }
                getPostData(CHATLIST_SP.getContactsList, params, successCallBack);
            } else {
                setTimeout(()=>{
                    userData = store.getState().loginDto.userData
                    if (!userData?.userId) {
                        return;
                    }
                    let params = {
                        "userId": userData?.userId,
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let contactsList = successMessage.dataList?.contactsList
                            callBack(contactsList);
                        } else {
                            callBack([]);
                            // alert('获取失败')
                        }
                    }
                    getPostData(CHATLIST_SP.getContactsList, params, successCallBack);
                }, 3000);
            }
        },
        getAddUserList: (getFindUserId: any, callBack?: any) => {
            // return (dispatcher: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "userId": userData?.userId,
                "getFindUserId": getFindUserId
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    let userList = successMessage.dataList.userList
                    callBack(userList)
                } else {
                    // alert('获取失败')
                }
            }
            getPostData(SET_SP.getFindUserList, params, successCallBack)
        },
        addUser: (addUserId: any, callBack?: any) => {
            // return (dispatcher: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "myUserId": userData?.userId,
                "otherUserId": addUserId
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    alert('添加失败')
                }
            }
            getPostData(SET_SP.addUserPush, params, successCallBack)
        },
        selectChat: (chatId: any, callBack?: any) => {
            const chatList = store.getState().chatDto.chatList
            chatList.map(async (chat:any)=>{
                if (chatId == chat?.chatId) {
                    store.dispatch({type:chatTypes.SET_SELECT_CHAT_DATA, selectChatData: chat})
                    callBack();
                }
            })
        },
        deleteMyUser: (otherUserId: any, chatId: any, callBack?: any) => {
            const userData = store.getState().loginDto.userData
            let params = {
                "myUserId": userData?.userId,
                "otherUserId": otherUserId,
                "chatId": chatId,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    callBack()
                    alert('删除失败')
                }
            }
            getPostData(SET_SP.deleteMyUser, params, successCallBack)
        },
        getChatList: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "userId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                    } else {
                        // alert('获取失败')
                    }
                }
                getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
            } else {
                setTimeout(()=>{
                    let params = {
                        "userId": userData?.userId,
                    }
                    if (!userData?.userId || userData?.userId == "") {
                        window.location.assign('/');
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let apiData = successMessage.dataList
                            store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                        } else {
                            // alert('获取失败')
                        }
                    }
                    getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
                }, 3000)
            }
        },
    }
}

function setToken() {
    throw new Error("Function not implemented.")
}
