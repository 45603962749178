import * as React from 'react';
import { Box, Button, CircularProgress, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tab, Tabs, TextField } from '@mui/material';
import { MyUserAction } from './myUserAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import './myUserPage.css'
import SearchIcon from '@mui/icons-material/Search';
import { UserInfo } from '../../components';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SelectAlert } from '../../components/SelectAlert';

interface Props {
    actions: typeof MyUserAction.actions
    loginDto: LoginDto
    commonDto: CommonDto
}

export class MyUserPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            selectTag: 0,
            loadingFlg: false,
            myUserList: [],
            inputBaseSearchValue: '',
            searchUserName: '',
            searchUserId: '',
            searchUserList: [],
            toChatFlg: false,
            deleteAlert: false,
            deleteUserId: '',
            deleteChatId: '',
        }
    }

    async componentDidMount() {
        this.getMyUserList();
        // TODO
        this.getChatList();
    }

    onChageTag(event: React.SyntheticEvent, index: number) {
        if (index == this.state.selectTag) {
            return;
        }

        this.setState({
            selectTag: index
        });
    }

    selectView(index: number) {
        const { userData }= this.props.loginDto
        const { selectTag, loadingFlg, myUserList, searchUserList } = this.state;
        if (index == 0) {
            return this.myUserListPage(myUserList);
        } else if (index == 1) {
            return this.addUserPage(searchUserList, userData)
        } else if (index == 2) {
            return this.myUserListSetPage(myUserList);
        } else {
            return (
                <div>error</div>
            )
        }
    }

    async getMyUserList() {
        try {
            this.setState({loadingFlg: true });
            const callback = (contactsList: any) => {
                this.setState({myUserList: contactsList, loadingFlg: false });
            }
            this.props.actions.getUserList(callback)
        } catch (e: any) {
            console.log(e);
        }
    }

    onChangeSearchUserName(event: any) {
        this.setState({ inputBaseSearchValue: event?.target?.value });
    }

    onClickSearchUserName() {
        const { inputBaseSearchValue } = this.state;
        this.setState({ searchUserName: inputBaseSearchValue });
    }

    myUserListShow(myUserList: any) {
        const { searchUserName } = this.state;
        if (searchUserName) {
            let newMyUserList: any = [];
            myUserList.map((user: any)=>{
                if (user?.userName.includes(searchUserName)) {
                    newMyUserList.push(user)
                }
            })
            return newMyUserList;
        } else {
            return myUserList;
        }

    }

    userNameShow(name: String) {
        return name.length > 10 ? name.substring(0, 10) + '...' : name;
    }

    onClickToChat(chat: String) {
        const callBack = () => {
            setTimeout(()=>{
                this.setState({toChatFlg: true})
            }, 200)
        }
        this.props.actions.selectChat(chat, callBack);
    }

    myUserListPage(myUserList: any) {
        return (
            <div className='myUserList_area'>
                <div className='user_seach'>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="输入好友昵称"
                            onChange={(event: React.ChangeEvent)=>this.onChangeSearchUserName(event) }
                            value={this.state?.inputBaseSearchValue}
                            // inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>this.onClickSearchUserName()}>
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </Paper>
                </div>
                {this.state.loadingFlg && <div className='loading_area'>
                    <CircularProgress />
                </div>}                
                <List className='myUserList' disablePadding>
                {
                    this.myUserListShow(myUserList).map((user: any)=>{
                        // return <div>{user?.userName}</div>
                        return (
                            <ListItem className='myUser'>
                                <ListItemButton sx={{padding: 0, height: '50px'}}>
                                    <ListItemIcon>
                                        <UserInfo style={{maxHeight: '30px'}} nameLeftFlg={false} userIconUrl={require('../../assets/img/0221019102904.png')} />
                                    </ListItemIcon>
                                    <ListItemText primary={this.userNameShow(user?.userName)} />
                                </ListItemButton>
                                <Button
                                    sx={{ width: 60, margin: 1, padding: 1 }} variant={user.isMyContactFlg ? "outlined" : 'contained'}
                                    onClick={()=>this.onClickToChat(user?.privateChatId)}
                                >
                                    {'聊 天'}
                                </Button>
                            </ListItem>
                        )
                    })
                }
                </List>
            </div>
        )
    }

    onChangeSearchUserId(event: any) {
        this.setState({ searchUserId: event?.target?.value });
    }
    onClickSearchUserId = (myUserId: string) => {
        const userId = this.state.searchUserId;
        const callback = (userList: any) => {
            this.setState({searchUserList: userList})
            if (userList.length == 0) {
                alert('用户不存在！')
            }
        }
        if (userId && myUserId == userId) {
            alert('不能添加自己')
        } else {
            this.props.actions.getAddUserList(userId, callback)
        }
    }
    async getChatList() {
        this.props.actions?.getChatList(()=>{})
    }
    onClickAddUser(addUserId: string) {
        const { userData } = this.props.loginDto;
        const callback = () => {
            this.getMyUserList();
            const back = (userList: any) => {
                this.setState({searchUserList: userList, loadingFlg: false})
                if (userList.length == 0) {
                    alert('用户不存在！')
                }
            }
            this.props.actions.getAddUserList(this.state.searchUserId, back)
        }
        if (addUserId == userData.userId) {
            alert('不能添加自己')
        } else {
            this.setState({loadingFlg: true})
            this.props.actions.addUser(addUserId, callback)
        }
    }

    addUserPage(searchUserList: any, userData: any) {
        return (
            <div className='addUserPage_area'>
                <div className='user_seach'>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="输入用户id"
                            onChange={(event: React.ChangeEvent)=>this.onChangeSearchUserId(event) }
                            value={this.state?.searchUserId}
                            // inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>this.onClickSearchUserId(userData?.userId)}>
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </Paper>
                </div>
                {this.state.loadingFlg && <div className='loading_area'>
                    <CircularProgress />
                </div>}                
                <List className='myUserList' disablePadding>
                {
                    searchUserList.map((user: any)=>{
                        // return <div>{user?.userName}</div>
                        return (
                            <ListItem className='myUser'>
                                <ListItemButton sx={{padding: 0, height: '50px'}}>
                                    <ListItemIcon>
                                        <UserInfo style={{maxHeight: '30px'}} nameLeftFlg={false} userIconUrl={require('../../assets/img/0221019102904.png')} />
                                    </ListItemIcon>
                                    <ListItemText primary={this.userNameShow(user?.userName)} />
                                </ListItemButton>
                                <Button
                                    sx={{ width: 60, margin: 1, padding: 1 }} variant={(!user?.isMyContactFlg && !user?.isSendAddFlg) ? "contained" : 'outlined'}
                                    onClick={()=> {
                                        if (!user?.isMyContactFlg && !user?.isSendAddFlg) {
                                            this.onClickAddUser(user?.userId)
                                        }
                                    }}
                                >
                                    {user?.isMyContactFlg ? '已添加' : (user?.isSendAddFlg ? '待同意' : '添  加') }
                                </Button>
                            </ListItem>
                        )
                    })
                }
                </List>
            </div>
        )
    }

    onClickDeleteUser(userId: string, deleteChatId: String) {
        this.setState({deleteAlert: true, deleteUserId: userId, deleteChatId: deleteChatId})
    }

    onClickCleanDelete() {
        this.setState({deleteAlert: false, deleteUserId: '', deleteChatId: ''})
    }

    onClickDeleteOk() {
        const {deleteUserId, deleteChatId} = this.state;
        const callBack = (userList: any) => {
            this.setState({deleteAlert: false, deleteUserId: '', deleteChatId: ''})
            this.getMyUserList();
            this.getChatList();
            if (userList.length == 0) {
                alert('已删除')
            }
        }
        this.props.actions.deleteMyUser(deleteUserId, deleteChatId, callBack)
    }

    myUserListSetPage(myUserList: any) {
        return (
            <div className='myUserList_area'>
                <div className='user_seach'>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="输入好友昵称"
                            onChange={(event: React.ChangeEvent)=>this.onChangeSearchUserName(event) }
                            value={this.state?.inputBaseSearchValue}
                            // inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>this.onClickSearchUserName()}>
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </Paper>
                </div>
                {this.state.loadingFlg && <div className='loading_area'>
                    <CircularProgress />
                </div>}
                <List className='myUserList' disablePadding>
                {
                    this.myUserListShow(myUserList).map((user: any)=>{
                        // return <div>{user?.userName}</div>
                        return (
                            <ListItem className='myUser'>
                                <ListItemButton sx={{padding: 0, height: '50px'}}>
                                    <ListItemIcon>
                                        <UserInfo style={{maxHeight: '30px'}} nameLeftFlg={false} userIconUrl={require('../../assets/img/0221019102904.png')} />
                                    </ListItemIcon>
                                    <ListItemText primary={this.userNameShow(user?.userName)} />
                                </ListItemButton>
                                <Button
                                    sx={{ width: 60, margin: 1, padding: 1 }} variant={user.isMyContactFlg ? "outlined" : 'contained'}
                                    onClick={()=>this.onClickDeleteUser(user?.userId, user?.privateChatId)}
                                >
                                    {'删 除'}
                                </Button>
                            </ListItem>
                        )
                    })
                }
                </List>
            </div>
        )
    }

    render() {
        const { selectTag, deleteAlert, toChatFlg, loadingFlg } = this.state
        return (
            <Box className='myUserPage' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: 0, margin: 0, width: '-webkit-fill-available', position: 'fixed', top: 64, bottom: 0 }}>
                {toChatFlg && <Navigate to="/chat" replace={true} />}
                <SelectAlert open={deleteAlert} text={'请确认是否删除该好友！！！'} onClicNo={()=>this.onClickCleanDelete()} onClickOk={()=>this.onClickDeleteOk()} />
                <Tabs onChange={(event: React.SyntheticEvent, index: number)=>this.onChageTag(event, index)}
                className='tag_area' value={selectTag} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab className='tag' label="好友一览" />
                    <Tab className='tag' label="添加好友" />
                    <Tab className='tag' label="好友管理" />
                    <Tab className='tag' label="思考..........." />
                </Tabs>
                <div className='contacts_area'>
                    <Paper sx={{flex: 1, margin: 1, padding: 1, display: 'flex'}}>
                        {this.selectView(selectTag)}
                    </Paper>
                </div>
            </Box>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const MyUserPageContainer = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(MyUserAction.actions, dispatch) })
)(MyUserPage)