import { UserInfo, MessageBubbles } from '../../../../components'
import * as React from 'react'
import './MessageArea.css'

type Props = {
    message: any
    myUser: any
}
export const MessageArea = (props: Props) => {
    const { message, myUser } = props;
    const myMessageFlg = message?.sendUserId==myUser?.userId;
    const userMessageStyle = myMessageFlg ? 'message-user-name-right' : 'message-user-name-left';
    const dateTime = message?.createDate.substring(0, 16);
    const sendName = message?.sendUserName.length > 6 ? message?.sendUserName?.substring(0, 6) + '...' : message?.sendUserName;
    return (
        <div className='message-area' style={{marginLeft: myMessageFlg ? '30%' : '0%', marginRight: myMessageFlg ? '0%' : '30%'}}>
            <div className='message-user' style={{flexDirection: myMessageFlg ? "row-reverse" : "row"}}>
                <UserInfo style={{maxHeight: '60px'}} nameLeftFlg={false} userIconUrl={require('../../../../assets/img/0221019102904.png')} />
                <div className={userMessageStyle}>
                    <div className='message-user-name'>
                        {sendName+' ('+dateTime+')'}
                    </div>
                    <MessageBubbles message={message} myUser={myUser} myMessageFlg={myMessageFlg} />
                </div>
            </div>
        </div>
    );
}
