// import { getWSUrl } from '../../common';
import { WX_CONFIG } from '../../common/config/wsConfig';
import React, { useState, useCallback, useEffect, useImperativeHandle } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ChatAction } from '@/pages/chat/chatAction';
import Cookies from 'js-cookie';
import { getPushValue } from './../../common';
var pushJs = require('push.js');

interface Props {
  pushCheck?: String
  userToKen?: String
  actions?: typeof ChatAction.actions
  chatId?: String
  getPushFun?: any
  wsOkCallback?: any
  wsPushCallback?: any
  wsAddUserMessageCallback?: any
}

const WebSocketChat = (props: Props) => {
  const { userToKen, chatId, getPushFun, wsPushCallback, pushCheck, wsOkCallback, wsAddUserMessageCallback } = props;
  let satoken: any = Cookies.get('userToken');
  if (userToKen) {
    satoken = userToKen;
  }
  const [socketUrl, setSocketUrl] = useState(WX_CONFIG.host+'/myto/dev/ws-connect?satoken=' + satoken);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        if (lastMessage?.data.includes('Web-Socket 连接成功')) {
          wsOkCallback();
        }
        let pushValue = getPushValue(lastMessage?.data);
        if (pushValue?.pushType == pushCheck) {
          wsPushCallback(pushValue);
        }
        if (pushValue?.pushType == 'out-push-addUserMessage') {
          wsAddUserMessageCallback(pushValue);
        }
        
        // pushJs.create('conversationName', {
        //   body: 'ChatTest',
        //   // icon: icon,
        //   timeout: 8000,
        //   onClick: function () {
        //     window.focus();
        //     this.close();
        //   },
        // });
      } catch (e: any) {
        console.log('TEST:'+e)
      }
    }
  }, [lastMessage]);

  const sendPushMessage = useCallback(
    () => {
      sendMessage('pushUserChatMessage,messageId-82w3upP7Kg-2024-05-17 18:15:58.284,ChatId-CohnIq1Dd2-2023-12-17 20:32:59.320,10086')
    }, []
  );

  // getPushFun(sendPushMessage);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
 
  return (
    <div>
      {/* 子组件的内容 */}
    </div>
  );
};

export default WebSocketChat;