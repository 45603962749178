// import { getWSUrl } from '../../common';
import { WX_CONFIG } from '../../common/config/wsConfig';
import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ChatAction } from '@/pages/chat/chatAction';
import Cookies from 'js-cookie';
var pushJs = require('push.js');

interface Props {
  userToKen?: String
  actions?: typeof ChatAction.actions
  showState?: boolean
}

export const WebSocketPush = (props: Props) => {
  const { userToKen, showState } = props;
  let satoken: any = Cookies.get('userToken');
  if (userToKen) {
    satoken = userToKen;
  }
  const [socketUrl, setSocketUrl] = useState('wss://api.nnnfhweubwys.bond:8081/myto/dev/ws-connect?satoken=' + satoken);
  const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev:any) => prev.concat(lastMessage));
      try {
        pushJs.create('conversationName', {
          body: 'bodyTest',
          // icon: icon,
          timeout: 8000,
          onClick: function () {
            window.focus();
            this.close();
          },
        });
      } catch (e: any) {
        console.log('TEST:'+e)
      }
    }
  }, [lastMessage, setMessageHistory]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div>
      {
        showState && <span>WS状态： {connectionStatus}</span>
      }
    </div>
  );
};