import { combineReducers, createStore } from "redux"

// 変数
export interface LoginDto {
    isLogin: boolean
    userData: any
}

// 変数初期値
export const initialState: any = {
    isLogin: false,
    userData: {
        userId: '',
        userName: ''
    }
}

// reducer定義
export const loginTypes = {
    LOGIN_ERROR: 'LOGIN/LOGIN_ERROR',
    SET_ISLOGIN: 'LOGIN/SET_ISLOGIN',
    SET_USER_DATA: 'LOGIN/SET_USER_DATA',
}

export function reducer(loginDto: LoginDto = initialState, action: any): LoginDto {
    switch (action.type) {
        case loginTypes.SET_ISLOGIN:
            return { ...loginDto, isLogin: action.isLogin }
        case loginTypes.SET_USER_DATA:
            return { ...loginDto, userData: action.userData }
        default:
            return loginDto
    }
}
