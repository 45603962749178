import { CHATLIST_SP, CHAT_SP, LOGIN_SP, SET_SP } from "../../common/springBoot/springBootUrl"
import { chatListFlertNew, getPostData } from "../../common/utils/utils"
import Cookies from 'js-cookie'
import { push } from "react-router-redux"
import { useNavigate } from "react-router-dom"
import { routerActions } from 'react-router-redux'
import { store } from "../../app/store"
import { chatTypes } from "../../common/dto/chat/chat.dto"
import chatList from "../chat/chatList/chatList"
import { commonTypes } from "../../common/dto/common/common.dto"
var pushJs = require('push.js');

export class SystemMessageAction {
    public static actions = {
        getSystemMessageList: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "myUserId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        store.dispatch({type:commonTypes.SET_NEW_SYSTEMMESSAGELIST, systemMessageList: apiData.syatemMessageList})
                        callBack(apiData.syatemMessageList)
                    } else {
                        alert('获取失败')
                    }
                }
                getPostData(SET_SP.getSystemMessagList, params, successCallBack)
            } else {
                setTimeout(()=>{
                    const userData = store.getState().loginDto.userData
                    if (!userData?.userId) {
                        return;
                    }
                    let params = {
                        "myUserId": userData?.userId,
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let apiData = successMessage.dataList
                            store.dispatch({type:commonTypes.SET_NEW_SYSTEMMESSAGELIST, systemMessageList: apiData.syatemMessageList})
                            callBack(apiData.syatemMessageList)
                        } else {
                            alert('获取失败')
                        }
                    }
                    getPostData(SET_SP.getSystemMessagList, params, successCallBack)
                },2000)
            }
        },
        addUserOk: (otherUserId: any, chatName: any, callBack?: any) => {
            const userData = store.getState().loginDto.userData
            let chatNameNew:any = {};
            chatNameNew[otherUserId] = userData?.userName;
            chatNameNew[userData?.userId] = chatName;

            var jsonString = JSON.stringify(chatNameNew);
            
            let params = {
                "myUserId": userData?.userId,
                "otherUserId": otherUserId,
                "chatName": jsonString,
            }
            const successCallBack = async (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else {
                    // alert('获取失败')
                    callBack()
                }
                callBack()
            }
            getPostData(SET_SP.agreeAddUser, params, successCallBack)
        },
        getChatList: (callBack?: any) => {
            let userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "userId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                    } else {
                        // alert('获取失败')
                    }
                }
                getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
            } else {
                setTimeout(()=>{
                    userData = store.getState().loginDto.userData
                    let params = {
                        "userId": userData?.userId,
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let apiData = successMessage.dataList
                            store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                        } else {
                            // alert('获取失败')
                        }
                    }
                    getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
                }, 3000)
            }
        },
    }
}

function setToken() {
    throw new Error("Function not implemented.")
}
