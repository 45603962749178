// 変数
export interface CommonDto {
    isLogin: boolean
    userData: any
    systemMessageList: any
    systemNewPushFlg: boolean
}

// 変数初期値
export const initialState: any = {
    isLogin: false,
    userData: {
        userId: '',
        userName: '',
        isLoginFlg: false,
    },
    systemMessageList: [],
    systemNewPushFlg: false,
}

// reducer定義
export const commonTypes = {
    LOGIN_ERROR: 'COMMON/LOGIN_ERROR',
    SET_ISLOGIN: 'COMMON/SET_ISLOGIN',
    SET_USER_DATA: 'COMMON/SET_USER_DATA',
    SET_NEW_SYSTEMMESSAGELIST: 'COMMON/SET_NEW_SYSTEMMESSAGELIST',
    SET_SYSTEM_NEW_PUSH_FLG: 'COMMON/SET_SYSTEM_NEW_PUSH_FLG',
}

export function reducer(commonDto: CommonDto = initialState, action: any): CommonDto {
    switch (action.type) {
        case commonTypes.SET_ISLOGIN:
            return { ...commonDto, isLogin: action.isLogin }
        case commonTypes.SET_USER_DATA:
            return { ...commonDto, userData: action.userData }
        case commonTypes.SET_NEW_SYSTEMMESSAGELIST:
            return { ...commonDto, systemMessageList: action.systemMessageList }
        case commonTypes.SET_SYSTEM_NEW_PUSH_FLG:
            return { ...commonDto, systemNewPushFlg: action.systemNewPushFlg }
        default:
            return commonDto
    }
}