import React from "react";
import './TestPage.css';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Link, Outlet } from "react-router-dom";

function TestIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

export class TestPage extends React.Component {
    render() {
        return <>
            <div className="App">
                <header className="App-header2">
                    <TestIcon color="primary" />
                    <nav>
                        <ul>
                            <li>
                            <Link to={`test2`}>Your Name</Link>
                            </li>
                            <li>
                            <Link to={`navigator`}>Your Friend</Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                <Outlet />
            </div>
        </>
    }
}
