import Cookies from 'js-cookie';
import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { store, history } from './app/store';
import { HomePage, TestPage, TestPage2, NavigatorPage, ContentPage01, DefaultErrorPage, Login } from './pages'
import { ChatPageContainer } from './pages/chatPage';
import { ChatM } from './pages/chat/chatPage.m';
import ContactsManage from './pages/chat/contactsManage/contactsManage';
import ChatLayout from './pages/chatx';
import { LoginAction } from './pages/login/loginAction';
import { Register } from './pages/register';
import LoadingPage from './pages/loadingPage/loadingPage';
import { MyUserChatPage, MyUserChatPageContainer } from './pages/myUserChatPage/myUserChatPage';
import { MyUserPageContainer } from './pages/myUserPage/myUserPage';
import { SystemMessagePageContainer } from './pages/systemMessagePage';
import { AddUsersChatPageContainer } from './pages/addUsersChatPage';

const homePage = createHashRouter([
  {
    path: "/",
    element: <NavigatorPage />,
    errorElement: <div>testError</div>,
    children: [
      {
        path: "/",
        element: <HomePage />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "default",
        element: <HomePage />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "navigator02",
        element: <ChatLayout />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "chat",
        // element: /Mobi|Android|iPhone/i.test(navigator.userAgent) ? <ChatM /> : <ChatPageContainer />,
        element: <ChatPageContainer />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "navigator04",
        element: <div>页面未制作----联系星护大人</div>,
        errorElement: <DefaultErrorPage />,
      },{
        path: "myUserChat",
        element: <MyUserChatPageContainer />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "myUser",
        element: <MyUserPageContainer />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "systemMessage",
        element: <SystemMessagePageContainer />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "addUsersChat",
        element: <AddUsersChatPageContainer />,
        errorElement: <DefaultErrorPage />,
      },{
        path: "chatlaoding",
        element: <LoadingPage />,
        errorElement: <DefaultErrorPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <div>testError</div>,
    // children: [
    //   {
    //     path: "error",
    //     element: <div>ERROR</div>,
    //     errorElement: <DefaultErrorPage />,
    //   },
    // ],
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <div>testError</div>,
    // children: [
    //   {
    //     path: "error",
    //     element: <div>ERROR</div>,
    //     errorElement: <DefaultErrorPage />,
    //   },
    // ],
  },
])

// const isLogin = async () => {
//   console.log('验证是否登录')
//   const token = store.get('token');
//   if (!token) return [false, {}];

//   const result = await vatifyToken(token);

//   if (!result.success) return [false, {}];

//   const { userInfo } = result;

//   return [true, userInfo];

// }

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={homePage} />
    </Provider>
  );
}

export default App;
