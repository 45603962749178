import * as React from 'react';
import { ChatAction } from './chatAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChatDto } from '../../common/dto/chat/chat.dto';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import './CovnSettingMenu.css';
import { Backdrop, Button, Checkbox, CircularProgress, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tab, Tabs, TextField } from '@mui/material';
import { SelectAlert, UserInfo } from '../../components';
import { Navigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { checkInArrayValue } from '../../common';

interface Props {
    actions: typeof ChatAction.actions
    chatDto: ChatDto
    loginDto: LoginDto
    commonDto: CommonDto
    isShowFlg: boolean
}

export class CovnPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            isExpanded: false,
            exitAlert: false,
            loading: false,
            toHomeFlg: false,
            editChatNameViewFlg: false,
            addChatUserViewFlg: false,
            chatUsersViewFlg: false,
            addChatName: '',
            inputBaseSearchValue: '',
            myUserList: [],
            chatUserList: [],
            searchUserName: '',
            addChatUserList: [],
        }
    }

    async componentDidMount() {
      try {
        this.getContactsList()
        this.getChatUserList()
      } catch (e: any) {
        console.log('e:'+e)
      }
    }

    async getContactsList() {
      const callback1 = (userList: any) => {
        this.setState({
            myUserList: userList
        })
      }
      this.props.actions.getContactsList(callback1)
    }

    async getChatUserList() {
      const callback2 = (userList: any) => {
        this.setState({
            chatUserList: userList
        })
      }
      this.props.actions.getChatUserList(this.props.chatDto?.selectChatData?.chatId, callback2)
    }

    toggleNavbar = () => {
        const { isExpanded } = this.state;
        this.setState({isExpanded: !isExpanded});
    };

    getChatList() {
      this.props.actions?.getChatList(()=>{})
    }

    onClickDeleteOk(exitTagLabel: String) {
      this.setState({loading: true, exitAlert: true})
      if (exitTagLabel == "退出群聊") {
        const callBack = () => {
            this.setState({loading: false, toHomeFlg: true})
            this.getChatList();
        }
        this.props.actions.exitChat(callBack)
      } else if (exitTagLabel == "删除群聊") {
        const callBack = () => {
            this.setState({loading: false, toHomeFlg: true})
            this.getChatList();
        }
        this.props.actions.deleteChat(callBack)
      } else if (exitTagLabel == "删除好友") {
        const callBack = () => {
            this.setState({loading: false, toHomeFlg: true})
            this.getChatList();
        }
        this.props.actions.deleteMyUser(callBack)
      } else {
        this.setState({loading: false})
        alert('error')
      }
    }

    isIdUnique(arr: any, userId: any) {
      return !arr.some((item: any) => item.userId === userId);
    }

    onChageChatName(chatName: String) {
      this.setState({
          addChatName: chatName
      })
    }

    onClickChangeChatNameOk() {
      const {addChatName} = this.state;
      this.setState({ addChatUserViewFlg: false, loading: true});
      const callBack = () => {
          this.setState({ editChatNameViewFlg: false, loading: false});
          this.getChatList();
      }
      this.props.actions.changeChatName(addChatName, callBack)
      setTimeout(()=>{
        this.setState({ loading: false});
      }, 5000)
    }

    editChatNameView() {
      return <TextField
        autoFocus
        required
        margin="dense"
        id="chatName"
        name="chatName"
        label="群聊名"
        variant="standard"
        sx={{width: '100%'}}
        onChange={e => this.onChageChatName(e?.currentTarget?.value)}
        value={this.state.addChatName}
      />
    }

    onChangeSearchUserName(event: any) {
      this.setState({ inputBaseSearchValue: event?.target?.value });
    }

    userNameShow(name: String) {
      return name.length > 10 ? name.substring(0, 10) + '...' : name;
    }

    handleToggle(userId: String) {
      const {addChatUserList} = this.state
      let check = checkInArrayValue(addChatUserList, userId)
      let newList = [...addChatUserList]
      if (check.check) {
        newList.splice(check.index, 1)
        this.setState({addChatUserList: newList})
      } else {
          this.setState({addChatUserList: [...newList, userId]})
      }
    };

    listItem(user: any, checkbox?: boolean) {
      const {addChatUserList} = this.state
      return (
          <ListItem className='userItem'>
              <ListItemButton sx={{padding: 0, height: '50px'}}>
                  <ListItemIcon>
                      <UserInfo style={{maxHeight: '30px'}} nameLeftFlg={false} userIconUrl={require('../../assets/img/0221019102904.png')} />
                  </ListItemIcon>
                  <ListItemText primary={this.userNameShow(user?.userName)} />
              </ListItemButton>
              {
                checkbox && <Checkbox
                  edge="end"
                  onChange={()=>this.handleToggle(user?.userId)}
                  checked={
                      // checkAddUserSelect(user.userId)
                      checkInArrayValue(addChatUserList, user?.userId).check
                  }
                />
              }
          </ListItem>
      )
    }

    myUserListShow(myUserList: any) {
      const { searchUserName } = this.state;
      if (searchUserName) {
          let newMyUserList: any = [];
          myUserList.map((user: any)=>{
              if (user?.userName.includes(searchUserName)) {
                  newMyUserList.push(user)
              }
          })
          return newMyUserList;
      } else {
          return myUserList;
      }
    }
    
    onClickChatAddUserOk() {
      const {addChatUserList} = this.state;
      this.setState({ addChatUserViewFlg: false, loading: true});
      setTimeout(()=>{
        this.setState({ loading: false});
      }, 5000)
      const callBack = () => {
          this.setState({ addChatUserViewFlg: false, loading: false});
          this.getChatUserList();
      }
      this.props.actions.addChatUser(addChatUserList, callBack)
    }
    
    addChatUserView() {
      const { myUserList, chatUserList, addChatUserList } = this.state;
      let userList: any = []
      myUserList.map((user: any)=>{
        if (!chatUserList.some((item: any) => item?.userId === user?.userId)) {
          userList.push(user)
        }
      })
      return <div className='addChatUser'>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="输入好友昵称"
              onChange={(event: React.ChangeEvent)=>this.onChangeSearchUserName(event) }
              value={this.state?.inputBaseSearchValue}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        <List className='userList' disablePadding>
        {
            userList ? this.myUserListShow(userList).map((user:any)=>{
                return this.listItem(user, true)
            }) : '没有好友可以选择'
        }
        </List>
        <Button className='addChatButton' variant={'outlined'} onClick={()=>this.onClickChatAddUserOk()}
          disabled={addChatUserList.length == 0}>添加好友</Button>
      </div>
    }

    chatUsersView() {
      const { chatUserList } = this.state;
      return <div className='addChatUser'>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="输入好友昵称"
              onChange={(event: React.ChangeEvent)=>this.onChangeSearchUserName(event) }
              value={this.state?.inputBaseSearchValue}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        <List className='userList' disablePadding>
        {
            chatUserList ? this.myUserListShow(chatUserList).map((user:any)=>{
                return this.listItem(user, false)
            }) : '没有群友'
        }
        </List>
      </div>
    }

    render() {
        const { isExpanded, exitAlert, loading, toHomeFlg, editChatNameViewFlg, addChatUserViewFlg, chatUsersViewFlg } = this.state
        const { isShowFlg } = this.props
        const { selectChatData } = this.props.chatDto
        const { userData } = this.props.loginDto
        const exitTagLabel = selectChatData?.isPrivate ? "删除好友" : userData.userId == selectChatData?.createUserId ? "删除群聊" : "退出群聊";
        return (
            <nav className="navbar">
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {toHomeFlg && <Navigate to="/" replace={true} />}
              <SelectAlert open={exitAlert} text={'请确认是否'+exitTagLabel+'！！！'}
                onClicNo={()=>this.setState({exitAlert: false})}
                onClickOk={()=>this.onClickDeleteOk(exitTagLabel)}
              />
              <SelectAlert open={editChatNameViewFlg} text={'请输入新的群聊名！！！'}
                onClicNo={()=>this.setState({editChatNameViewFlg: false})} 
                onClickOk={()=>this.onClickChangeChatNameOk()}
                maxWidth={'500px'} fullWidth={true}
                view={this.editChatNameView()}
              />
              <SelectAlert open={addChatUserViewFlg} text={'请选择群友！！！'} 
                onClicNo={()=>this.setState({addChatUserViewFlg: false})}
                notButtonOkFlg={true} notButtonNoFlg={true} maxWidth={'500px'} fullWidth={true}
                view={this.addChatUserView()}
              />
              <SelectAlert open={chatUsersViewFlg} text={'群友一览！！！'}
                onClicNo={()=>this.setState({chatUsersViewFlg: false})}
                notButtonOkFlg={true} maxWidth={'500px'} fullWidth={true}
                view={this.chatUsersView()}
              />
              <div className="navbar-content">
                <ul className={`navbar-menu ${isShowFlg ? 'is-expanded' : ''}`}>
                  <Tabs  className={`navbar-menu ${isShowFlg ? 'is-expanded' : ''}`}
                    variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                      {!selectChatData?.isPrivate && <Tab className='tag' onClick={()=>this.setState({chatUsersViewFlg: true})} label="用户一览" />}
                      {!selectChatData?.isPrivate && <Tab className='tag' onClick={()=>this.setState({addChatUserViewFlg: true})} label="添加群友" />}
                      {!selectChatData?.isPrivate && <Tab className='tag' onClick={()=>this.setState({editChatNameViewFlg: true})} label="修改群名" />}
                      <Tab className='tag' onClick={()=>this.setState({exitAlert: true})} label={exitTagLabel} />
                  </Tabs>
                </ul>
              </div>
          </nav>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        chatDto: state.chatDto,
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const CovnPageContainer = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(ChatAction.actions, dispatch) })
)(CovnPage)