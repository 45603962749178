import { Avatar, Fade, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import React from 'react'
import './UserIcon.css'

type Props = {
  userName?: String
  iconUrl?: any
  onPress?: any
  style?: any
  userNameStyle?: any
  userNameRight?: boolean
  iconStyle?: any
  iconAlt?: string
  iconChildren?: any
  onClickLogout?: any
}

export const UserIcon = (props: Props) => {
  const { userName, iconUrl, style, userNameStyle, iconStyle, iconAlt, iconChildren, userNameRight } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    setAnchorEl(null);
    props.onClickLogout();
  }
  return (
    <Toolbar className='UserIcon_Area' style={style}>
      {
        userName && !userNameRight && <Typography variant="h6" noWrap component="div" sx={userNameStyle}>
          {userName}
        </Typography>
      }
      <Grid item sx={{}} >
          <IconButton color="inherit" sx={{ p: 0.8 }} onClick={handleClick}>
            {
              iconChildren ? (
                <Avatar sx={iconStyle} >
                  {iconChildren}
                </Avatar>
              ) : (
                <Avatar sx={iconStyle} src={iconUrl?iconUrl:require('../../assets/img/test.PNG')} alt={iconAlt?iconAlt:"userIcon"} />
              )
            }
          </IconButton>
          <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={logout}>退 出</MenuItem>
      </Menu>
      </Grid>
      {
        userName && userNameRight && <Typography variant="h6" noWrap component="div" sx={userNameStyle}>
          {userName}
        </Typography>
      }
    </Toolbar>
  )
}
