import { CHATLIST_SP, CHAT_SP, LOGIN_SP, SET_SP } from "../../common/springBoot/springBootUrl"
import { chatListFlertNew, getPostData } from "../../common/utils/utils"
import Cookies from 'js-cookie'
import { push } from "react-router-redux"
import { useNavigate } from "react-router-dom"
import { routerActions } from 'react-router-redux'
import { store } from "../../app/store"
import { chatTypes } from "../../common/dto/chat/chat.dto"
var pushJs = require('push.js');

export class AddUsersChatAction {
    public static actions = {
        getUserList: (callBack?: any) => {
            const userData = store.getState().loginDto.userData
            if (userData?.userId) {
                const userData = store.getState().loginDto.userData
                let params = {
                    "userId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let contactsList = successMessage.dataList?.contactsList
                        callBack(contactsList);
                    } else {
                        callBack([]);
                        alert('获取失败')
                    }
                }
                getPostData(CHATLIST_SP.getContactsList, params, successCallBack);
            } else {
                setTimeout(()=>{
                    const userData = store.getState().loginDto.userData
                    let params = {
                        "userId": userData?.userId,
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let contactsList = successMessage.dataList?.contactsList
                            callBack(contactsList);
                        } else {
                            callBack([]);
                            alert('获取失败')
                        }
                    }
                    getPostData(CHATLIST_SP.getContactsList, params, successCallBack);
                }, 5000);
            }
        },
        getChatList: (callBack?: any) => {
            let userData = store.getState().loginDto.userData
            if (userData?.userId) {
                let params = {
                    "userId": userData?.userId,
                }
                const successCallBack = async (successMessage: any) => {
                    if (successMessage.code === '200') {
                        let apiData = successMessage.dataList
                        store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                    } else {
                        // alert('获取失败')
                    }
                }
                getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
            } else {
                setTimeout(()=>{
                    userData = store.getState().loginDto.userData
                    let params = {
                        "userId": userData?.userId,
                    }
                    const successCallBack = async (successMessage: any) => {
                        if (successMessage.code === '200') {
                            let apiData = successMessage.dataList
                            store.dispatch({type:chatTypes.SET_CHAT_LIST, chatList: chatListFlertNew(apiData.chatList, userData?.userId)})
                        } else {
                            // alert('获取失败')
                        }
                    }
                    getPostData(CHATLIST_SP.getChatLists, params, successCallBack)
                }, 3000)
            }
        },
        createRoom: (chatName: any, chatUser: any, callBack?: any) => {
            const userId = store.getState().loginDto.userData?.userId
            const userList = [...chatUser, userId]
            let params = {
                "getChatName": chatName,
                "userId": userId,
                "chatUser": userList
            }
            const successCallBack = (successMessage: any) => {
                if (successMessage.code === '200') {
                    callBack()
                } else if (successMessage.code === '202') {
                    alert('聊天室名和用户不可用！！')
                } else {
                    alert('创建失败！！')
                }
            }
            getPostData(CHATLIST_SP.createChat, params, successCallBack)
        },
    }
}

function setToken() {
    throw new Error("Function not implemented.")
}
