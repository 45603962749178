import React from 'react'
import './errorPage.css'

 export class DefaultErrorPage extends React.Component {
    render() {
        return <>
            <div className="errorPage">
                发生了我也不知道的错误,哈哈哈哈~
            </div>
        </>
    }
 }