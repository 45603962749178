import * as React from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tab, Tabs, TextField } from '@mui/material';
import { AddUsersChatAction } from './addUsersChatAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import './addUsersChatPage.css'
import { UserInfo, SelectAlert } from '../../components';
import { checkInArrayValue } from '../../common';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    actions: typeof AddUsersChatAction.actions
    loginDto: LoginDto
    commonDto: CommonDto
}

export class AddUsersChatPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            myUserList: [],
            addAlert: false,
            loadingFlg: false,
            addChatName: '',
            addChatUserList: [],
            inputBaseSearchValue: '',
        }
    }

    async componentDidMount() {
        this.getMyUserList();
        this.getChatList();
    }

    async getMyUserList() {
        try {
            this.setState({loadingFlg: true });
            const callback = (contactsList: any) => {
                this.setState({myUserList: contactsList, loadingFlg: false });
            }
            this.props.actions.getUserList(callback)
        } catch (e: any) {
            console.log(e);
        }
    }

    userNameShow(name: String) {
        return name.length > 10 ? name.substring(0, 10) + '...' : name;
    }

    handleToggle(userId: String) {
        const {addChatUserList} = this.state
        let check = checkInArrayValue(addChatUserList, userId)
        let newList = [...addChatUserList]
        if (check.check) {
          newList.splice(check.index, 1)
          this.setState({addChatUserList: newList})
        } else {
            this.setState({addChatUserList: [...newList, userId]})
        }
    };

    listItem(user: any) {
        const {addChatUserList} = this.state
        return (
            <ListItem className='userItem'>
                <ListItemButton sx={{padding: 0, height: '50px'}}>
                    <ListItemIcon>
                        <UserInfo style={{maxHeight: '30px'}} nameLeftFlg={false} userIconUrl={require('../../assets/img/0221019102904.png')} />
                    </ListItemIcon>
                    <ListItemText primary={this.userNameShow(user?.userName)} />
                </ListItemButton>
                <Checkbox
                    edge="end"
                    onChange={()=>this.handleToggle(user?.userId)}
                    checked={
                        // checkAddUserSelect(user.userId)
                        checkInArrayValue(addChatUserList, user?.userId).check
                    }
                />
                
            </ListItem>
        )
    }

    onClickAdd() {
        this.setState({
            addAlert: true,
        })
    }

    onClickCleanAdd() {
        this.setState({addAlert: false,})
    }

    async getChatList() {
        this.props.actions?.getChatList(()=>{})
    }

    onClickAddOk() {
        const {addChatName, addChatUserList} = this.state;
        const callBack = () => {
            this.setState({addAlert: false, addChatName: '', addChatUserList: []});
            this.getChatList();
        }
        this.props.actions.createRoom(addChatName, addChatUserList, callBack)
    }

    myUserListShow(myUserList: any) {
        const { searchUserName } = this.state;
        if (searchUserName) {
            let newMyUserList: any = [];
            myUserList.map((user: any)=>{
                if (user?.userName.includes(searchUserName)) {
                    newMyUserList.push(user)
                }
            })
            return newMyUserList;
        } else {
            return myUserList;
        }
    }

    onChangeSearchUserName(event: any) {
        this.setState({ inputBaseSearchValue: event?.target?.value });
    }

    onClickSearchUserName() {

    }

    onChageChatName(chatName: String) {
        this.setState({
            addChatName: chatName
        })
    }

    setChatNameView() {
        return (
            <TextField
                autoFocus
                required
                margin="dense"
                id="chatName"
                name="chatName"
                label="群聊名"
                variant="standard"
                sx={{width: '100%'}}
                onChange={e => this.onChageChatName(e?.currentTarget?.value)}
                value={this.state.addChatName}
            />
        )
    }

    render() {
        const { myUserList, addAlert, loadingFlg, addChatUserList } = this.state
        return (
            <Box className='addUsersChatPage' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: 0, margin: 0, width: '-webkit-fill-available', position: 'fixed', top: 64, bottom: 0 }}>
                <h3 className='headText'>创建群聊</h3>
                <div className='contacts_area'>
                    <SelectAlert open={addAlert} headText={'输入群聊名'} text={''} okText={'创建'} fullWidth={true}
                        onClicNo={()=>this.onClickCleanAdd()} onClickOk={()=>this.onClickAddOk()} view={this.setChatNameView()} />
                    <Paper sx={{flex: 1, margin: 1, padding: 1, display: 'flex', flexDirection: 'column'}}>
                        {loadingFlg && <div className='loading_area'>
                            <CircularProgress />
                        </div>}
                        <div className='user_seach'>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="输入好友昵称"
                                    onChange={(event: React.ChangeEvent)=>this.onChangeSearchUserName(event) }
                                    value={this.state?.inputBaseSearchValue}
                                    // inputProps={{ 'aria-label': 'search google maps' }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>this.onClickSearchUserName()}>
                                    <SearchIcon />
                                </IconButton>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            </Paper>
                        </div>
                        <List className='userList' disablePadding>
                        {
                            this.myUserListShow(myUserList).map((message:any)=>{
                                return this.listItem(message)
                            })
                        }
                        </List>
                        <Button className='addChatButton' disabled={addChatUserList.length == 0}
                            onClick={()=>this.onClickAdd()}
                        >新建群聊</Button>
                    </Paper>
                </div>
            </Box>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const AddUsersChatPageContainer = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(AddUsersChatAction.actions, dispatch) })
)(AddUsersChatPage)