// import { getWSUrl } from '../../common';
import { WX_CONFIG } from '../../common/config/wsConfig';
import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ChatAction } from '@/pages/chat/chatAction';
import Cookies from 'js-cookie';
var pushJs = require('push.js');

interface Props {
  userToKen?: String,
  actions?: typeof ChatAction.actions
}

export const WebSocket = (props: Props) => {
  const { userToKen } = props;
  let satoken: any = Cookies.get('userToken');
  if (userToKen) {
    satoken = userToKen;
  }
  const [socketUrl, setSocketUrl] = useState('wss://api.nnnfhweubwys.bond:8081/myto/dev/ws-connect?satoken=' + satoken);

  const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev:any) => prev.concat(lastMessage));
      try {
        pushJs.create('conversationName', {
          body: 'bodyTest',
          // icon: icon,
          timeout: 8000,
          onClick: function () {
            window.focus();
            this.close();
          },
        });
      } catch (e: any) {
        console.log('TEST:'+e)
      }
    }
  }, [lastMessage, setMessageHistory]);

  const handleClickChangeSocketUrl = useCallback(
    () => setSocketUrl('wss://api.nnnfhweubwys.bond:8081/myto/dev/ws-connect?satoken=957714de-5dbb-4f9f-9130-36c47daed00b'),
    []
  );

  const handleClickSendMessage = useCallback(() => sendMessage('pushUserChatMessage,messageId-82w3upP7Kg-2024-05-17 18:15:58.284,ChatId-CohnIq1Dd2-2023-12-17 20:32:59.320,10086'), []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div>
      <button onClick={handleClickChangeSocketUrl}>
        Click Me to change Socket Url
      </button>
      <button
        onClick={handleClickSendMessage}
        disabled={readyState !== ReadyState.OPEN}
      >
        Click Me to send 'Hello'
      </button>
      <span>The WebSocket is currently {connectionStatus}</span>
      {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
      <ul>
        {messageHistory.map((message: any, idx) => (
          <span key={idx}>{message ? message?.data : null}</span>
        ))}
      </ul>
    </div>
  );
};