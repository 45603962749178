import * as React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './NavigatorPage.css'
import { Link, Navigate, NavLink, Outlet, useHref, useLinkClickHandler, useLocation, useNavigate } from 'react-router-dom';

import { PAGE_DATA } from '../../testData/pageData'
import { Backdrop, Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import { getPageId, getPath, getPostData, isLogin, LOGIN_SP } from '../../common';
import { UserIcon, UserIconMent, WebSocketPush } from '../../components';
import { store } from '../../app/store';
import Cookies from 'js-cookie';
import { ChatAction } from '../chatPage/chatAction';
import WebSocketChat from '../../components/WebSocket/WebSocketChat';
import { Diversity3, Person } from '@mui/icons-material';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import { ChatDto } from '../../common/dto/chat/chat.dto';


const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,

    // 内容和导航栏边距
    padding: theme.spacing(0),

    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // 内容必须低于应用程序栏
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function Navigator(props: any) {
    const theme = useTheme();
    const { contentModular, secondaryModular, chatList, selectEdChatId, addUserPushFlg } = props
    let chatListData: any = chatList;
    let selectChatId: string = selectEdChatId;
    // if (!chatListData || !selectChatId) {
    //     chatListData = store.getState().chatDto.chatList;
    //     selectChatId = store.getState().chatDto.selectChatData?.chatId;
    // }
    const [open, setOpen] = React.useState(false);
    const [toLogion, setToLogin] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.pathname;
    const getNowPageId = getPageId()
    const userData = store.getState().loginDto.userData
    const getNowPath = getPath()

    // 判断是否登录
    const isLoginCheck = async () => {
        const checkCallback = (flg: any) => {
            if (!flg) {
                setToLogin(true)
            }
        }
        isLogin(checkCallback)
    };

    // 打开侧边导航栏 
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    // 关闭侧边导航栏
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const onClickChat = async (chatId: String) => {
        if (toLogion) {
            navigate('/login')
        } else {
            const callBack = () => {
                navigate('/chatlaoding')
                setTimeout(()=>{
                    navigate('/chat')
                },100)
            }
            ChatAction.actions.selectChat(chatId, callBack)
        }
        if (/Mobi|Android|iPhone/i.test(navigator.userAgent) && open === true) {
            handleDrawerClose();
        }
    }
    const onClickPage = async (pageId: String) => {
        if (toLogion) {
            navigate('/login')
        } else {
            navigate('/chatlaoding')
            setTimeout(()=>{
                navigate('/'+pageId)
            },100)
        }
        if (/Mobi|Android|iPhone/i.test(navigator.userAgent) && open === true) {
            handleDrawerClose();
        }
    }

    const onClickOut = () => {
        Cookies.remove('userToken')
        let params = {
            "test": 'Test'
        }
        const successCallBack = (successMessage: any) => {
            if (successMessage.code === '200') {
                window.location.reload()
            }
        }
        getPostData(LOGIN_SP.loveDev, params, successCallBack)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {toLogion && <Navigate to="/login" replace={true} />}
            <CssBaseline />
            {/* <WebSocketPush /> */}
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ width: '300px' }}>
                        网站建成中
                    </Typography>
                    <UserIcon userName={userData ? userData.userName : '用户名取得失败'} 
                        onClickLogout={()=>onClickOut()}
                        iconUrl={require('../../assets/img/0221019102904.png')} />
                    {/* <UserIconMent /> */}
                </Toolbar>

            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {/* UI内置路由管理 */}
                {/* <Navigate to="/default" replace={true} /> */}
                <div className='navigotor_area'>
                    <div className='chatlist_area'>
                        {chatListData.map((chat: any, index: any) => (
                            <ListItem disablePadding sx={{backgroundColor: selectChatId == chat?.chatId ? 'aliceblue' : ''}}>
                                <ListItemButton onClick={() => onClickChat(chat.chatId)}>
                                    <ListItemIcon>
                                        {
                                            chat?.isPrivate ? <Person /> : <Diversity3 />
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={chat?.chatName} />
                                </ListItemButton>
                                {
                                    chat?.newMessageFlg && <div className='notification-dot'></div>
                                }
                            </ListItem>
                        ))}
                    </div>
                    <Divider />
                    <List>
                        {secondaryModular.map((secondaryModular: any, index: any) => (
                            <ListItem key={secondaryModular.pageId} disablePadding>
                                <ListItemButton onClick={()=>onClickPage(secondaryModular?.pageId)}>
                                    <ListItemIcon>
                                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={secondaryModular.title} />
                                </ListItemButton>
                                {
                                    (secondaryModular.pageId == 'systemMessage' && addUserPushFlg) && <div className='notification-dot'></div>
                                }
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
            {/* 子页面内容(固定) */}
            {/* <Outlet /> */}

            {/* 子页面内容 */}
            <Main open={open} className='main'>
                <DrawerHeader />
                {
                    /Mobi|Android|iPhone/i.test(navigator.userAgent) && open === true &&
                    <button style={{ left: drawerWidth, zIndex: 10 }} className='main_button' onClick={handleDrawerClose}></button>
                }
                {/* <Box sx={{ width: open ? `calc(100% - ${drawerWidth}px)` : '100%' }}> */}
                {/* <Box sx={{ width: `calc(100vw - ${drawerWidth}px)`}}> */}
                <Box className='main_view' sx={{ backgroundColor:'#b31c74' }}>
                    <Outlet />
                </Box>

            </Main>
        </Box>
    );
}

interface Props {
    actions: typeof ChatAction.actions
    chatDto: ChatDto
    loginDto: LoginDto
    commonDto: CommonDto
}

export class NavigatorP extends React.Component<Props, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            date: new Date(),
            contentModular: PAGE_DATA?.navigator?.contentModular,
            secondaryModular: PAGE_DATA?.navigator?.secondaryModular,
            notLoginFlg: false,
            open: false,
            chatList: [],
            loading: false,
            wsLoading: true,
        };
    }

    componentDidMount() {
        setTimeout(()=>{
            this.setState({ wsLoading: false })
        }, 5000);
        this.setState({ open: true })
        this.setState({
            contentModular: PAGE_DATA?.navigator?.contentModular,
            secondaryModular: PAGE_DATA?.navigator?.secondaryModular,
        })
        const checkCallback = (flg: any) => {
            if (!flg) {
                this.setState({ notLoginFlg: true, open: false })
            } else {
                this.setState({ open: false })
            }
            this.getChatList()
        }
        isLogin(checkCallback)
    }

    getChatList() {
        const successCallBack = (chatList: any) => {
            const callBack = (chatList: any) => {
                this.setState({ chatList: chatList, loading: false })
            }
            // ChatAction.actions?.setChatList(chatList, callBack)
            this.setState({ chatList: chatList, loading: false })
        }
        ChatAction.actions?.getChatList(successCallBack)
    }

    newMessage(data: any) {
        ChatAction.actions?.newMessage(data);
    }

    newAddUserMessage(data: any) {
        ChatAction.actions?.getSystemMessageList(data);
    }

    wsOkCallback() {
        this.setState({
            wsLoading: false
        })
    }

    render() {
        const {chatDto, commonDto} = this.props
        return <>
            {this.state.notLoginFlg && <Navigate to="/login" replace={true} />}
            <Navigator selectEdChatId={chatDto?.selectChatData?.chatId} chatList={chatDto?.chatList} contentModular={this.state.contentModular} secondaryModular={this.state.secondaryModular} addUserPushFlg={commonDto?.systemNewPushFlg} />
            <WebSocketChat pushCheck='out-push-newMessage' wsOkCallback={()=>this.wsOkCallback()} wsPushCallback={(data:any)=>this.newMessage(data)} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!(!this.state.loading && !this.state.wsLoading)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    }
}

function mapStateToProps(state: any) {
    return {
        chatDto: state.chatDto,
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const NavigatorPage = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(ChatAction.actions, dispatch) })
)(NavigatorP)