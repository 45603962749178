import * as React from 'react'
import { AnyIfEmpty } from 'react-redux'
import './MessageBubbles.css'

type Props = {
    message: any
    myUser: any
    myMessageFlg?: boolean
    style?: any
}
export const MessageBubbles = (props: Props) => {
    const { message, myUser, myMessageFlg, style } = props;
    const messageBubbleStyle = myMessageFlg ? 'message-bubble-right' : 'message-bubble-left';
    const isIphoneFlg = /Mobi|Android|iPhone/i.test(navigator.userAgent);
    
    return (
        <div className='message-bubble-area' style={style}>
            <div className={isIphoneFlg?messageBubbleStyle+'-m':messageBubbleStyle}>{message?.messageText}</div>
        </div>
    );
}
