import React from 'react';

const useStyles = {
    container: {
        bottom: 0,
        position: 'fixed'
    },
    bubbleContainer: {
        width: '100%'
    },
    bubble: {
        border: '0.5px solid black',
        borderRadius: '10px',
        margin: '5px',
        padding: '10px',
        display: 'inline-block'
    }
}

const ChatLayout = () => {
    const classes = useStyles
    const dummyData = [
        {
            message: '1: This should be in left',
            direction: 'left'
        },
        {
            message: '2: This should be in right',
            direction: 'right'
        },
        {
            message: '3: This should be in left again',
            direction: 'left'
        }
    ];

    const chatBubbles = dummyData.map((obj, i = 0) => (
        <div style={classes.bubbleContainer}>
            <div key={i++} style={classes.bubble}>
                <div>{obj.message}</div>
            </div>
        </div>
    ));
    return <div>{chatBubbles}</div>;
};

export default ChatLayout;