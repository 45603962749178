import { store } from '../../app/store';
import * as React from 'react';
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { SPRING_BOOT_CONFIG } from '../config';
import { PAGES } from "../config/pageConfig";
import Cookies from 'js-cookie';
import { push, replace } from 'react-router-redux'
import { LOGIN_SP } from '../springBoot';
import { loginTypes } from '../dto/login/login.dto';

// const navigate = useNavigate();

export const getPageId = (path?: String) => {
    try {
        let pageId = ''
        let pageList = PAGES
        if (path) {
            pageList.forEach(page => {
                if (page.path === path) {
                    pageId = page?.pageId
                }
            })
        } else {
            let nowPath = window.location?.pathname
            pageList.forEach(page => {
                if (page.path === nowPath) {
                    pageId = page?.pageId
                }
            })
        }
        return pageId
    } catch (e) {
        console.log('Utils-ERROR-getNowPageId:', e)
        return 'error'
    }
}

export const getPath = (pageId?: String) => {
    try {
        let path = ''
        let pageList = PAGES
        if (pageId) {
            pageList.forEach(page => {
                if (page?.pageId === pageId) {
                    pageId = page?.pageId
                }
            })
        } else {
            path = window.location?.pathname
        }
        return path
    } catch (e) {
        console.log('Utils-ERROR-getNowPath:', e)
        return 'error'
    }
}

export const getSBUrl = (urlData: any) => {
    let url = SPRING_BOOT_CONFIG.host + SPRING_BOOT_CONFIG.contextPath + SPRING_BOOT_CONFIG.version
    url = url + urlData
    return url
}


export const getPostData = (urlData: any, params: any, callBack?: any) => {
    let url = getSBUrl(urlData)
    let successMessage = {
        success: false,
        message: 'API连接失败',
        dataList: [],
        code: 400
    }
    var getInformation = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
    }
    fetch(url, getInformation).then(
        res => {
            if (res.status === 200) {
                res.json().then(
                    (data) => {
                        successMessage.success = data.success
                        successMessage.message = data.message
                        successMessage.dataList = data.dataList
                        successMessage.code = data.code
                        callBack(successMessage)
                        return successMessage
                    }
                ).catch(
                    () => {
                        successMessage.message = '数据转换失败！code:' + res?.status
                        successMessage.code = 301
                    }
                )
            } else {
                successMessage.message = '取得数据服务失败！code:' + res?.status
                successMessage.code = res?.status
                callBack(successMessage)
                return successMessage
            }
        }
    ).catch(() => {
        successMessage.message = '取得数据服务失败！code:505'
        successMessage.code = 505
        callBack(successMessage)
        return successMessage
    }
    )
}

export const isLogin = (loginCallback?: any) => {
    console.log('验证是否登录')
    const storeIsLoginFlg = store.getState().loginDto.isLogin
    if (storeIsLoginFlg) {
        loginCallback(true)
        return true
    } else {
        let userToKen = Cookies.get('userToken')
        let userId = Cookies.get('loginUserId')
        let params = {
            "userId": userId,
            "userToKen": userToKen
        }
        const successCallBack = (successMessage: any) => {
            if (successMessage.code === '200') {
                let apiData = successMessage.dataList
                if (apiData.isLoginFlg) {
                    console.log('checkLogin:True')
                    let userData = {
                        userId: userId,
                        userName: apiData.userData.userName
                    }
                    store.dispatch({type:loginTypes.SET_ISLOGIN, isLogin: true})
                    store.dispatch({type:loginTypes.SET_USER_DATA, userData: userData})
                    loginCallback(true)
                    return true
                } else {
                    loginCallback(false)
                    return false
                }
            } else {
                loginCallback(false)
                return false
            }
        }
        getPostData(LOGIN_SP.checkLogin, params, successCallBack)
    }
}

export const checkLogin = (loginCallback: any, noLoginCallback: any) => {
    if (isLogin()) {
        loginCallback()
    } else {
        noLoginCallback()
    }
}

export const checkInArrayValue = (array: Array<String>, value: String) => {
    // array.forEach(element => {
        
    // });
    let checkData = {
        check: false,
        index: 0
    }
    array.map((v, index)=>{
        if (v == value) {
            checkData.check = true
            checkData.index = index
        }
    })
    return checkData
}

export const getPushValue = (message: String) => {
    let valueList = message.split(':,:');
    let data: any = {};
    valueList.map((value, index)=>{
        let array = value.split(':#:');
        if (array.length == 2) {
            data[array[0]] = array[1];
        }
    })
    return data;
}

export const getPrivateChatName = (chatNameList: string, myUserId: string) => {
    var parsedObj = JSON.parse(chatNameList);
    let chatName = '未命名私有聊天室';
    if (parsedObj[myUserId]) {
        chatName = parsedObj[myUserId];
    }
    return chatName;
}

export const chatListFlertNew= (chatList: any, myUserId: string) => {
    chatList.map((chat:any)=>{
        if (chat?.isPrivate == true) {
            chat.chatNameY = chat?.chatName;
            chat.chatName = getPrivateChatName(chat?.chatName, myUserId)
        }
    })
    return chatList;
}