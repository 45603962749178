import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Box, CircularProgress, ListItem, TextField } from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ChatAction } from './chatAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useWebSocket } from "react-use-websockets";
import { useEffect, useRef } from 'react';
import WebSocketChat from '../../components/WebSocket/WebSocketChat';
import { ChatDto } from '../../common/dto/chat/chat.dto';
import { CommonDto } from '../../common/dto/common/common.dto';
import { LoginDto } from "../../common/dto/login/login.dto";
import { CovnPageContainer } from './CovnSettingMenu';
import './chatPage.css'
import { MessageArea } from './components/MessageArea';

const useStyles = {
  container: {
    bottom: 0,
    position: 'fixed'
  },
  bubbleContainer: {
    width: '100%'
  },
  bubble: {
    border: '0.5px solid black',
    borderRadius: '10px',
    margin: '5px',
    padding: '10px',
    display: 'inline-block'
  }
}

interface Props {
    actions: typeof ChatAction.actions
    chatDto: ChatDto
    loginDto: LoginDto
    commonDto: CommonDto
}

export class ChatPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            userId: '',
            messagesList: [],
            contactList: [],
            loading: false,
            text: '',
            notChatFlg: false,
            isShowChatSettingFlg: false,
            newMessageInfo: {},
        }
    }
    wxFun = null

    async componentDidMount() {
        if (this.props.chatDto?.selectChatData?.chatId) {
            this.setState({
                loading: true
            })
            const callback = (messages: any) => {
                if (messages.length > 0) {
                    let list: any = []
                    messages.map((message: any) => {
                        let msg = {
                            messageId: message.messageId,
                            messageText: message?.text,
                            createDate: message?.createDate,
                            sendUserName: message?.userName,
                            messageType: message?.messageType,
                            sendUserId: message?.sendUserId,
                        }
                        list.push(msg)
                    })
                    this.setState({
                        messagesList: list,
                        loading: false
                    })
                    this.toNewMessage()
                } else {
                    setTimeout(()=>{
                        this.setState({
                            loading: false
                        })
                    }, 500)
                }
            }
            this.props.actions.getChatMessageList(this.props.chatDto?.selectChatData?.chatId, callback)
        } else {
            this.setState({notChatFlg: true});
            return;
        }
    }

    toNewMessage() {
        setTimeout(function () {
            let messageArea = document.getElementById('chat_message_area');
            if (messageArea) {
                messageArea.scrollTop = 100000000;
            }
        }, 200)
    }

    // 当props变化时更新state
    static getDerivedStateFromProps(nextProps:any, prevState:any) {
        // 当props的value发生变化时更新state
        if (nextProps?.chatDto?.newMessage?.messageId &&nextProps?.chatDto?.newMessage != prevState?.newMessageInfo) {
            const newMsgData = nextProps?.chatDto?.newMessage;
            if (nextProps?.chatDto?.selectChatData?.chatId != newMsgData?.chatId) {
                return null;
            }
            const newMsg = {
                messageId: newMsgData.messageId,
                messageText: newMsgData?.text,
                createDate: newMsgData?.createDate,
                sendUserName: newMsgData?.userName,
                messageType: newMsgData?.messageType,
                sendUserId: newMsgData?.sendUserId,
            }
            const newMessagesList = [...prevState?.messagesList, newMsg];
            setTimeout(function () {
                let messageArea = document.getElementById('chat_message_area');
                if (messageArea) {
                    messageArea.scrollTop = 100000000;
                }
            }, 200)
            return { newMessageInfo: nextProps?.chatDto?.newMessage, messagesList: newMessagesList };
        }
        // 如果没有变化，返回null
        return null;
    }

    onChangeText(value: String) {
        this.setState({
            text: value
        })
    }
    // getPushFun(fun: any) {
    //     this.wxFun = fun;
    // }
    onClickSendMessage() {
        const { selectChatData } = this.props?.chatDto
        const { text } = this.state
        if (text) {
            const callback = (isOk: boolean) => {
                if (isOk) {
                    this.setState({
                        text: ''
                    })
                    var test: any = this.wxFun
                    if (test) {
                        test();
                    }
                    // TODO 临时用
                    const callback = (messages: any) => {
                        if (messages.length > 0) {
                            let list: any = []
                            messages.map((message: any) => {
                                let msg = {
                                    messageId: message.messageId,
                                    messageText: message?.text,
                                    createDate: message?.createDate,
                                    sendUserName: message?.userName,
                                    messageType: message?.messageType,
                                    sendUserId: message?.sendUserId,
                                }
                                list.push(msg)
                            })
                            this.setState({
                                messagesList: list
                            })
                            this.toNewMessage()
                        }
                    }
                    // this.props.actions.getChatMessageList(selectChatData?.chatId, callback)

                } else {
                    alert('发送失败')
                }
            }
            this.props.actions.createMessage(selectChatData?.chatId, text, "", "TXT", callback)
        }
    }

    toggleNavbar = () => {
        const { isShowChatSettingFlg } = this.state;
        this.setState({isShowChatSettingFlg: !isShowChatSettingFlg});
    };

    render() {
        const { selectChatData } = this.props?.chatDto
        const { userData } = this.props?.loginDto
        const { messagesList, text, notChatFlg, loading, isShowChatSettingFlg } = this.state
        return (
            <Box className='chatPage' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: 0, margin: 0, width: '-webkit-fill-available', position: 'fixed', top: 64, bottom: 0 }}>
                {notChatFlg && <Navigate to="/" replace={true} />}
                <div className='chatHeard'>
                    <h2 className='chatName'>{selectChatData?.chatName}</h2>
                    <div 
                        className="navbar-toggle" 
                        onClick={() => this.toggleNavbar()}
                    >
                        ☰
                    </div>
                </div>
                <hr style={{ width: '100%', margin: 0, padding: 0, }} />
                {/* <WebSocketChat getPushFun={(e: any) => this.getPushFun(e)} /> */}
                <CovnPageContainer isShowFlg={isShowChatSettingFlg} />
                <List id="chat_message_area"
                    sx={{ backgroundColor: '#ffffff', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                    {loading && <div className='getMessageloading'>
                        <CircularProgress />
                    </div>}
                    {
                        !loading && messagesList.map(
                            (message: any) => {
                                // return <Box style={useStyles.bubble}>
                                //     <Box>{message?.messageText}</Box>
                                // </Box>
                                return <MessageArea message={message} myUser={userData} />
                            }
                        )
                    }
                </List>
                {
                    !loading && <Box sx={{ display: 'flex', width: '100%' }}>
                        <TextField
                            id="filled-multiline-flexible"
                            // label="Multiline2"
                            multiline
                            maxRows={4}
                            // variant="outlined"
                            sx={{ margin: 0, padding: 0.5, width: '100%' }}
                            onChange={e => this.onChangeText(e?.currentTarget?.value)}
                            value={text}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5 }}>
                            <button onClick={() => this.onClickSendMessage()} style={{ width: 50, height: 30 }}>发送</button>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        chatDto: state.chatDto,
        commonDto: state.commonDto,
        loginDto: state.loginDto,
    }
}

export const ChatPageContainer = connect(mapStateToProps,
    (dispatch) => ({ actions: bindActionCreators(ChatAction.actions, dispatch) })
)(ChatPage)