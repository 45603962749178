import { Button, Container, Grid, Paper, StyledEngineProvider, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { StrictMode } from "react";
import { connect } from "react-redux";
import { Link, useActionData } from "react-router-dom";
import { bindActionCreators } from "redux";
import { RegisterAction } from "./RegisterAction";

interface Props {
    // your props validation
    actions: typeof RegisterAction.actions
}

export class RegisterPage extends React.Component<Props, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            userName: '',
            userId: '',
            password: '',
            checkPassword: '',
            userMail: '',
        }
    }

    componentDidMount() {

    }

    onChangeUserName(userName: String) {
        this.setState({ userName: userName })
    }

    onChangeLoginId(userId: String) {
        this.setState({ userId: userId })
    }

    onChangePassword(password: String) {
        this.setState({ password: password })
    }

    onChangeCheckPassword(password: String) {
        this.setState({ checkPassword: password })
    }

    onChangeCheckeUserMail(userMail: String) {
        this.setState({ userMail: userMail })
    }

    onClickRegister() {
        const { userName, userId, password, checkPassword, userMail } = this.state
        if (userName && userId && password && checkPassword) {
            if (password === checkPassword) {
                const successCallBack = () => {
                    alert('注册成功')
                }
                if (userId && password) {
                    this.props.actions?.register(userName, userId, password, checkPassword, userMail, successCallBack)
                }
            } else {
                alert('两次输入的密码不一致')
            }
        }
    }

    render() {
        const { userName, userId, password, checkPassword } = this.state
        return (
            <StrictMode>
                <StyledEngineProvider injectFirst>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#e2f5ff',
                        top: 0,
                        bottom: 0,
                        position: 'absolute',
                    }}>
                        <Container maxWidth="sm" fixed sx={{ }}>
                            <Paper
                                sx={{
                                    p: 2,
                                    margin: 'auto',
                                    maxWidth: 500,
                                    padding: 1,
                                    flexGrow: 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    marginTop: '10%',
                                    marginRight: 1,
                                    marginLeft: 1,
                                    // opacity: 0.3,
                                }}
                            >
                                <h2 style={{textAlign: 'center'}}>注册账号</h2>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm container>
                                        <TextField sx={{ width: '100%', margin: 1 }} id="textField-loginName" label="用户昵称" variant="outlined" onChange={e => this.onChangeUserName(e?.currentTarget?.value)} value={this.state.userName} />
                                        <TextField sx={{ width: '100%', margin: 1 }} id="textField-loginId" label="用户ID" variant="outlined" onChange={e => this.onChangeLoginId(e?.currentTarget?.value)} value={this.state.userId} />
                                        <TextField sx={{ width: '100%', margin: 1 }} id="textField-password" label="用户密码" variant="outlined" onChange={e => this.onChangePassword(e?.currentTarget?.value)} value={this.state.password} />
                                        <TextField sx={{ width: '100%', margin: 1 }} id="textField-check-password" label="确认密码" variant="outlined" onChange={e => this.onChangeCheckPassword(e?.currentTarget?.value)} value={this.state.checkPassword} />
                                        <TextField sx={{ width: '100%', margin: 1 }} id="textField-eMail" label="邮箱" variant="outlined" onChange={e => this.onChangeCheckeUserMail(e?.currentTarget?.value)} value={this.state.userMail} />
                                        <Button 
                                            sx={{ width: '100%', margin: 1 }} variant={userName && userId && password && checkPassword ? "contained" : 'outlined'}
                                            onClick={()=>this.onClickRegister()}
                                        >注  册</Button>
                                    </Grid>
                                </Grid>
                                <Link to={'/login'}>已有用户，去登陆</Link>
                            </Paper>
                        </Container>
                    </Box>
                </StyledEngineProvider>
            </StrictMode>
        )
    }
}

function mapStateToProps(state: any) {
    return {
      authDto: state.authDto,
      commonDto: state.commonDto
    }
}
  
export const Register = connect(mapStateToProps,
(dispatch) => ({ actions: bindActionCreators(RegisterAction.actions, dispatch) })
)(RegisterPage)