import { Avatar, IconButton } from '@mui/material'
import * as React from 'react'
import './UserInfo.css'

type Props = {
    sendUserName?: any
    userId?: any
    userIconUrl?: any
    iconAlt?: string
    nameLeftFlg?: boolean
    style?: any
}
export const UserInfo = (props: Props) => {
    const { sendUserName, userId, userIconUrl, iconAlt, nameLeftFlg, style } = props
    return (
        <IconButton color="inherit" className='user-info-area' style={style}>
            {nameLeftFlg && <div>{sendUserName}</div>}
            <Avatar src={userIconUrl?userIconUrl:require('../../assets/img/test.PNG')} alt={iconAlt?iconAlt:"userIcon"} />
            {!nameLeftFlg && <div>{sendUserName}</div>}
        </IconButton>
    );
}
